import moment from "moment";
import { momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import EventCalendar from "./components/EventCalendar";

moment.locale("en-GB");
const localizer = momentLocalizer(moment);

const EventCalendarPage = () => {

    return (
        <div className="flex flex-col mt-6 items-center">
            <div className="container">
                <EventCalendar />
            </div>
        </div>
    )
}

export default EventCalendarPage