import { MdCastForEducation } from "react-icons/md";
import { Button, Text } from "@chakra-ui/react";
import { SlBadge } from "react-icons/sl";
import { BsCircle } from "react-icons/bs";
import React, { useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { divide } from "lodash";

interface IProps {
    programName: string,
    programCode: string,
    noOfCredit: string,
    status?: string,
    program_id: string,
    color: string,
    mode: string,
}

const ProgramDetailCard: React.FC<IProps> = ({
    program_id,
    programName,
    programCode,
    noOfCredit,
    status,
    color,
    mode
}) => {
    const navigate: NavigateFunction = useNavigate();
    const [onHover, setHover] = useState(false)

    const onClickMoreHandler = () => {
        navigate(`${program_id}`)
    }

    const bgColor = color ? color : '#5FFA2933'

    return (
        <div onClick={onClickMoreHandler} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} className="p-4 flex flex-col gap-5 rounded-2xl bg-white shadow hover:-translate-y-1 cursor-pointer group transition-all">
            <div className="flex justify-between items-center">
                <div className=" flex flex-row justify-center items-center p-[6px] rounded-full relative" style={{ backgroundColor: bgColor }}>
                    <BsCircle className="text-blue-700 opacity-0 group-hover:opacity-100 transition-all duration-200" size={24} />
                    <BsCircle className="text-blue-700 absolute opacity-0 group-hover:opacity-100 transition-all duration-200" size={15} />
                </div>
                <div>
                    <span className="bg-green-100 text-green-800 text-xs font-medium px-2.5 py-1 rounded">{mode}</span>
                </div>
            </div>
            <div className="h-[70px] overflow-hidden">
                <Text className="line-clamp-3 text-ellipsis overflow-hidden text-md font-semibold opacity-90">{programName}</Text>
            </div>
            <Text className="text-xs text-gray-700">{programCode}</Text>
            <div className="flex justify-between items-center ">
                <div className="flex justify-between items-end gap-2">
                    <div className="bg-[#dbf8ef] rounded-full p-1">
                        <BsCircle className="text-[#78e5c3]" size={15} />
                    </div>

                    <Text className="self-center text-sm text-gray-700">No of Credits {noOfCredit}</Text>
                </div>
            </div>
        </div>
    )
}


export default ProgramDetailCard