import { Select } from "@chakra-ui/react";
import _ from "lodash";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { FaRegCalendarTimes } from "react-icons/fa";
import { PiMonitorPlay } from "react-icons/pi";
import { TbTools } from "react-icons/tb";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { CALENDAR } from "../../../../consts/methods.consts";
import { CALENDAR_TAGS } from "../../../../consts/tags.consts";
import { useGetQuery } from "../../../../services/api.service";

moment.locale("en-GB");
const localizer = momentLocalizer(moment);

const EventCalendar = () => {
    const navigate: NavigateFunction = useNavigate()
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [filter, setFilter]: any = useState({
        startDateOfMonth: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
        endDateOfMonth: moment().add(1, 'months').endOf('month').format('YYYY-MM-DD')
    })

    const { data: _data, isLoading } = useGetQuery({
        method: CALENDAR.GET_EVENTS,
        body: {
            filter
        },
        providesTags: [CALENDAR_TAGS.EVENTS]
    })

    const data = _.get(_data, ['message'], [])

    const onSelectEvent = (event: any) => {
        navigate(`/app/events/${event.id}`)
    }

    const tooltipAccessor = (event: any) => {
        return event?.title
    }

    const Toolbar = (props: any) => {
        const [month, setMonth] = useState(moment().month() + 1)
        const [year, setYear] = useState(moment().year())

        const onChange = (e: any, type: any) => {
            if (type == 'year') {
                setYear(e.target.value)
            }

            if (type == 'month') {
                setMonth(e.target.value)
            }

        }

        useEffect(() => {
            setFilter({
                startDateOfMonth: moment(`${year}-${month}`, 'YYYY-MM')
                    .subtract(1, 'months')
                    .format('YYYY-MM-DD'),
                endDateOfMonth: moment(`${year}-${month}`, 'YYYY-MM')
                    .add(1, 'months')
                    .format('YYYY-MM-DD'),
            });

            setSelectedDate(moment(`${year}-${month}`, 'YYYY-MM').toDate())
        }, [month, year])

        return (
            <div className="space-y-3 md:space-y-0 md:grid md:grid-cols-3 py-3 bg-white">
                <div className="flex gap-3">
                    <MonthDropDown onChange={onChange} />
                    <YearDropdown onChange={onChange} />
                </div>
                <div className="flex justify-start md:justify-center items-center">
                    <p>{moment(props?.date).format('MMMM YYYY')}</p>
                </div>
                <div className="flex justify-start md:justify-end text-sm">
                    <div className="flex gap-4">
                        <div className="flex gap-2 items-center">
                            <div style={{ backgroundColor: 'rgba(246, 221, 191, 0.4)' }} className="w-[30px] h-[30px] rounded-full">

                            </div>
                            <div>
                                <p>Academic Deadline</p>
                            </div>
                        </div>

                        <div className="flex gap-2 items-center">
                            <div style={{ backgroundColor: 'rgba(191, 246, 213, 0.4)' }} className="w-[30px] h-[30px] rounded-full ">

                            </div>
                            <div>
                                <p>Webinar</p>
                            </div>
                        </div>

                        <div className="flex gap-2 items-center">
                            <div style={{ backgroundColor: 'rgba(191, 210, 246, 0.4)' }} className="w-[30px] h-[30px] rounded-full ">

                            </div>
                            <div>
                                <p>Workshop</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        )
    }

    const { views }: any = useMemo(
        () => ({
            views: {
                month: true,
                // agenda: XceedSessionAgenda,
            },
        }), [])


    const { components } = useMemo(
        () => ({
            components: {
                event: Event,
                toolbar: Toolbar,
                // resourceHeader: MyResourceHeader,
            },
        }),
        []
    )

    const eventStyleGetter = (event: any, start: any, end: any, isSelected: any) => {
        const backgroundColor = _.get(eventColourByType, event?.type);
        const style = {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            backgroundColor,
            borderRadius: '5px',
            color: 'black',
            border: '0',
            display: 'block',
        };
        return {
            style: style
        };
    };

    return (
        <div className="bg-white p-4 rounded-xl shadow">
            <Calendar
                views={views}
                date={selectedDate}
                selectable
                localizer={localizer}
                components={components}
                eventPropGetter={eventStyleGetter}
                startAccessor='start'
                endAccessor='end'
                popup
                defaultDate={new Date()}
                // onRangeChange={onRangeChange}
                tooltipAccessor={tooltipAccessor}
                defaultView="month"
                events={data || []}
                style={{ height: "80vh" }}
                onSelectEvent={onSelectEvent}
            />
        </div>
    )
}

export default EventCalendar


const Event = ({ event }: any) => {
    return (

        <div className="flex text-sm items-center !gap-2 p-1">
            <div>
                {_.get(iconByType, event.type)}
            </div>
            <div className="truncate">
                {event.title}
            </div>
        </div>
    )
}

const eventColourByType = {
    "Academic Deadline": "rgba(246, 221, 191, 0.4)",
    "Webinar": "rgba(191, 246, 213, 0.4)",
    "Workshop": "rgba(191, 210, 246, 0.4)"
}

const iconByType = {
    "Academic Deadline": <FaRegCalendarTimes size={20} />,
    "Workshop": <TbTools size={20} />,
    "Webinar": <PiMonitorPlay size={20} />
}

const YearDropdown = ({ onChange }: any) => {
    const [years, setYears] = useState([]);
    const [selectedYear, setSelectedYear] = useState(moment().year());

    useEffect(() => {
        const currentYear = moment().year();
        const yearRange: any = Array.from({ length: 11 }, (_, index) => currentYear - 5 + index);
        setYears(yearRange);
    }, []);

    return (
        <Select onChange={(e: any) => {
            onChange(e, 'year')
            setSelectedYear(e.target.value)
        }} style={{ backgroundImage: 'none' }} className={`!text-[Roboto] !pb-[2px] !pt-[2px]`} w={60} borderColor={'gray.300'} size={'sm'} rounded={'md'} value={selectedYear} >
            {years.map((year: any, index: number) => (
                <option key={index} value={year}>
                    {year}
                </option>
            ))}
        </Select>
    );
};

const MonthDropDown = ({ onChange = (e: any) => { } }: any) => {
    const months: any = [
        { label: 'January', value: 1 },
        { label: 'February', value: 2 },
        { label: 'March', value: 3 },
        { label: 'April', value: 4 },
        { label: 'May', value: 5 },
        { label: 'June', value: 6 },
        { label: 'July', value: 7 },
        { label: 'August', value: 8 },
        { label: 'September', value: 9 },
        { label: 'October', value: 10 },
        { label: 'November', value: 11 },
        { label: 'December', value: 12 }
    ]

    return (
        <Select className={`!pb-[2px] !pt-[2px]`} style={{ backgroundImage: 'none' }} onChange={(e) => { onChange(e, 'month') }} w={36} borderColor={'gray.300'} size={'sm'} rounded={'md'} defaultValue={moment().month() + 1} >
            {months.map((item: any, index: number) => (
                <option key={index} value={item.value}>
                    {item.label}
                </option>
            ))}
        </Select>
    );
}