import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, SimpleGrid, Text } from "@chakra-ui/react";
import _ from "lodash";
import { Link, useParams } from "react-router-dom";
import { ALLPROGRAM } from "../../../../consts/methods.consts";
import { useGetQuery } from "../../../../services/api.service";
import ModuleDetailsCard from "./components/ModuleDetailsCard";
import InnerLoading from "../../../common/inner-loading/InnterLoading";
import PageHeader from "../../../common/page-header-v2/PageHeader";
import React, { useRef, useState } from "react";
import { FaAngleRight } from "react-icons/fa";
import FilterPanel from "../../../common/FilterPanle/FilterPanel";
import EmptyResult from "../../../common/empty-result/emptyResult";
import FilterSelectedTag from "../../../common/FilterSelectedTag/FilterSelectedTag";

const ModuleListPage = () => {
    const { id } = useParams()
    const [filterPanelData, setFilterPanelData] = useState({})
    const filterRef: any = useRef(() => { })

    const { data, isLoading, isFetching } = useGetQuery({
        method: ALLPROGRAM.LISTMODULES,
        body: {
            program: id,
            ...filterPanelData
        },
        providesTags: []
    })

    const modules: Array<any> = _.get(data, 'message.modules', [])
    const program_name: string = _.get(data, 'message.program_name', '')

    const onFilter = (result: any) => {
        setFilterPanelData(result)
    }

    const onCloseTag = (key: any, index: any) => {
        filterRef.current.removeFilter(key, index)
    }

    const contentMarkup = (
        modules.length === 0 ? <EmptyResult title="No Programs" /> :
            (<SimpleGrid gridGap="8"
                className="mt-8 pt-1 overflow-auto"
                columns={{ base: 1, md: 2, lg: 3 }} gap={{ base: '4', md: '7' }}>
                {modules.map((line: any, index: number) => (
                    <ModuleDetailsCard key={index} code={line?.code} title={line?.title} module_id={line?.module_id}
                        type={line?.type} credit={line?.credit} duration_days={line?.duration_days} />
                ))}
            </SimpleGrid>)
    )

    const BreadcrumbMarkup = (
        <Breadcrumb size={"sm"} className=" w-full block"
            separator={<FaAngleRight className=" font-extralight" color='gray.500' />}>
            <BreadcrumbItem>
                <Link to={'/app/all-programs'}><BreadcrumbLink href='#'>All Programs</BreadcrumbLink></Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
                <Link to={'#'}><BreadcrumbLink href='#'>{id}</BreadcrumbLink></Link>
            </BreadcrumbItem>
        </Breadcrumb>
    )

    const actionMarkup = (
        <div className="flex gap-4 w-[50%] items-center justify-end ">
            <FilterSelectedTag onCloseTag={onCloseTag} filter={filterPanelData} />
            <FilterPanel ref={filterRef} onFilter={onFilter} api={ALLPROGRAM.GET_MODULE_FILTER_PANEL_DATA} />
        </div>
    )

    return (

        <div className="flex flex-col items-center">
            <div className="container mt-4">
                {BreadcrumbMarkup}
                <div className="mt-8">
                    <PageHeader isLoading={isLoading || isFetching} title="Program" subtitle={program_name}
                        actionRenderer={actionMarkup} />
                    {
                        (isFetching || isLoading) ? <InnerLoading height={'75vh'} /> : contentMarkup
                    }
                </div>
            </div>
        </div>
    )
}

export default ModuleListPage