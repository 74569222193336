import _ from "lodash";
import * as Yup from "yup";
import { useEffect, useRef } from "react";
import { useFormik } from "formik";
import { STUDENT, USER } from "../../../consts/methods.consts";
import ChakraModal from "../../common/chakra-modal/ChakraModal";
import { FormInput } from "../../common/form-controllers/FormInput";
import { STUDENT_TAG, USER_TAG } from "../../../consts/tags.consts";
import { FormDropdown } from "../../common/form-controllers/FormDropdown";
import { ToastService } from "../../../services/toast.service";
import { useObjChange } from "../../hooks";
import { FormTextArea } from "../../common/form-controllers/FormTextArea";
import { useGetQuery, usePostMutation } from "../../../services/api.service";
import { PageHeaderActions } from "../../common/page-header/PageHeader";
import ChakraTable, { ChakraTableColumns } from "../../common/chakra-table/ChakraTable";
import { SectionCard, SectionCardCreatable } from "../../common/section-card/SectionCard";
import { useNavigate, useParams } from "react-router-dom";
import { Avatar, Button, SimpleGrid, Stack, Text, useDisclosure, useToast } from "@chakra-ui/react";
import MaskFormInput from "../../common/form-controllers/MaskFormInput";
import { customeJsonParser } from "../../../utils/utils";
import PageHeader from "../../common/page-header-v2/PageHeader";
import { FormNumberInput } from "../../common/form-controllers/FormNumberInput";
import FormSelect from "../../common/form-controllers/FormSelect";
import { showConfirmDialog } from "../../../store/reducers/confirm-modal";
import { useDispatch, useSelector, useStore } from "react-redux";
import ProfileAvatar from "../../common/ProfileAvatar/ProfileAvatar";

const StudentProfilePage = () => {
    const email = useSelector((state:any)=>_.get(state,'common.user.email',''))
    const navigate = useNavigate()
    const disclosure = useDisclosure()
    const dispatch: any = useDispatch()
    const selectRef = useRef(false)
    const indexRef: any = useRef<number>()
    const workExperienceDisclosure = useDisclosure()
    const toast = new ToastService(useToast());
    const [create] = usePostMutation();
    const isErrorRef = useRef<boolean>(true)


    const { data: _data, isLoading, isError, error } = useGetQuery({
        method: USER.GET,
        body: { email },
        providesTags: [USER_TAG.VIEW]
    })

    const data = _.get(_data, ['message'], {})

    const stringifyData = JSON.stringify(data)

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            nic: Yup.string().matches(/^([0-9]{9}[x|X|v|V]|[0-9]{12})$/, 'Enter valid NIC number').required('NIC is required'),
            // user: Yup.string().required("User is required"),
            mobile_number: Yup.string().matches(/^[0-9]{10}$/, 'Enter valid mobile number'),
            whatsapp_number: Yup.string().matches(/^[0-9]{10}$/, 'Enter valid Whatsapp mobile number'),
            place_of_work_official_number: Yup.string().matches(/^[0-9]{10}$/, 'Enter valid mobile number'),
        }),
        onSubmit: async (values: any) => {
            try {
                await create({
                    method: STUDENT.UPDATE,
                    body: values,
                    invalidatesTags: [STUDENT_TAG.VIEW, STUDENT_TAG.LIST,USER_TAG.VIEW]
                }).unwrap();

                toast.setTitle("Success").setDescription("Student details have been saved.").showSuccessToast();

            } catch (error: any) {
                let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
                if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
            }
        }
    })

    const { isChanged, setInitialObj } = useObjChange(formik.values)

    useEffect(() => {
        if (!_.isEmpty(data)) {
            formik.setValues(data)
            setInitialObj(data)
        }
    }, [stringifyData])

    useEffect(() => {
        if (isError && isErrorRef.current) {
            isErrorRef.current = false
            const message: string = JSON.parse(JSON.parse(_.get(error, 'data._server_messages', ""))[0])?.message
            toast.setTitle("Error").setDescription(message).showErrorToast();
            navigate("/app")
        }
    }, [error]);

    const actions: PageHeaderActions = [
        {
            text: "Save",
            isDisabled: !isChanged,
            onClick: formik.submitForm,
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        }
    ]

    const creatable: SectionCardCreatable = {
        text: "Add New",
        onClick: () => {
            disclosure.onOpen();
        }
    }

    const workExperienceCreatable: SectionCardCreatable = {
        text: "Add New",
        onClick: () => {
            workExperienceDisclosure.onOpen();
        }
    }

    const EDUCATION_TABLE_COLUMNS: ChakraTableColumns = [
        {
            header: "Level",
            accessor: "level",
        },
        {
            header: "Title",
            accessor: "title",
        },
        {
            header: "Obtained Year",
            accessor: "obtained_year",
        },
        {
            header: "Action",
            accessor: "",
            width: 100,
            options: [
                {
                    label: "Delete",
                    onClick(item: any) {
                        const newItems = _.get(formik.values, 'educational_qualifications', []).filter((i: any) => (
                            i !== item
                        ))
                        formik.setFieldValue('educational_qualifications', newItems)
                    },
                }
            ]
        },
    ]

    const WORK_EXPERIENCE_TABLE_COLUMNS: ChakraTableColumns = [
        {
            header: "Designation",
            accessor: "designation",
        },
        {
            header: "Work Place",
            accessor: "work_place",
        },
        {
            header: "from",
            accessor: "from",
        },
        {
            header: "To",
            accessor: "to",
        },
        {
            header: "Action",
            accessor: "",
            width: 100,
            options: [
                {
                    label: "Delete",
                    onClick(item: any) {
                        const newItems = _.get(formik.values, 'student_work_experience', []).filter((i: any) => (
                            i !== item
                        ))
                        formik.setFieldValue('student_work_experience', newItems)
                    },
                },
                {
                    label: "Edit",
                    confirmation: false,
                    onClick(item: any) {
                        selectRef.current = true
                        indexRef.current = _.get(formik.values, 'student_work_experience', []).findIndex(
                            (line: any) =>
                                (line.designation === item.designation && line.work_place === item.work_place && line.from === item.from && line.to === item.to)
                        )
                        workExperienceChildFormik.setValues({ ...item })
                        workExperienceDisclosure.onOpen()
                    },
                }
            ]
        },
    ]


    const childFormik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            level: Yup.string().required("Level is required"),
            title: Yup.string().required("title is required"),
            obtained_year: Yup.string().required("Obtained Year is required"),
        }),
        onSubmit: async (values: any) => {

            disclosure.onClose()
            formik.setFieldValue('educational_qualifications', [
                ..._.get(formik.values, 'educational_qualifications', []), values
            ])

            childFormik.resetForm()
        }
    })

    const workExperienceChildFormik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            designation: Yup.string().required("Designation is required"),
            work_place: Yup.string().required("Work Place is required"),
            from: Yup.string().required("From Year is required"),
            to: Yup.string().required("to Year is required"),
        }),
        onSubmit: async (values: any) => {

            const alreadyExsist = _.get(formik.values, 'student_work_experience', []).filter((item: any) => (
                values.from === item.from &&
                values.to === item.to &&
                values.designation === item.designation &&
                values.work_place === item.work_place
            ))

            if (_.size(alreadyExsist) > 0) {
                toast.setDescription('This is already exists').setStatus('warning').setTitle('Warning').show()
                return
            }

            if (selectRef.current) {
                const ModuleContent: any = [..._.get(formik.values, 'student_work_experience', [])]
                ModuleContent[indexRef.current] = { ...values }
                formik.setFieldValue('student_work_experience', ModuleContent)
                workExperienceDisclosure.onClose()
                workExperienceChildFormik.resetForm()
                selectRef.current = false
                return
            }

            formik.setFieldValue('student_work_experience', [
                ..._.get(formik.values, 'student_work_experience', []), values
            ])

            workExperienceDisclosure.onClose()
            workExperienceChildFormik.resetForm()
        }
    })

    const onCloseworkExperienceModal = () => {
        selectRef.current = false
        workExperienceChildFormik.resetForm()
        workExperienceDisclosure.onClose()
    }


    return (
        <div className="flex flex-col mt-6 items-center">
            <div className="container">
                <PageHeader isLoading={isLoading} actions={actions}
                    title={_.get(data, 'full_name',false) ? _.get(data, 'full_name'): _.get(data,'nic')}
                    subtitle={'Student'} />

                <div className="flex flex-col md:grid md:grid-cols-4 gap-4">
                    <SectionCard className="col-span-3 shadow !bg-white rounded-2xl" title="Basic Information" p={5}>
                        <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 3 }} spacing={3}>
                            <FormInput isRequired label="NIC" formik={formik} name="nic" />
                            <FormDropdown options={salutation} formik={formik}
                                name={'salutation'}
                                autoFocus
                                label={'Salutation'} />
                            <FormInput label="First Name" formik={formik} name="first_name" />
                            <FormInput label="Last Name" formik={formik} name="last_name" />
                            <FormInput label="Full Name" formik={formik} name="full_name" />
                            {/* <FormInput label="Name with Initials" formik={formik} name="name_with_initials"/> */}
                            <FormInput type="date" label="Date of Birth" formik={formik} name="date_of_birth" />
                            <FormDropdown options={gender} formik={formik}
                                name={'gender'}
                                autoFocus
                                label={'Gender'} />

                            <FormInput type="date" label="From" formik={formik} name="from" />
                            <FormInput type="date" label="To" formik={formik} name="to" />
                            <FormSelect method={STUDENT.GET_SERVICES}
                                label="Service" formik={formik}
                                name="service" />
                        </SimpleGrid>
                    </SectionCard>
                    <SectionCard className="p-5 shadow md:p-0 flex justify-center items-center col-span-1 !bg-white rounded-2xl">
                        <ProfileAvatar placeholder={_.get(data, 'full_name', '')?_.get(data, 'full_name', ''):_.get(data, 'email', '')} docname={_.get(data, 'name')} photoUrl={_.get(formik.values, 'profie_picture')} />
                    </SectionCard>
                </div>

                <SectionCard backgroundColor="bg-white" title="Official Information" p={5}>
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 4 }} spacing={3}>
                        <FormInput label="Place of Work" formik={formik} name="place_of_work" />
                        <FormInput label="Present Designation" formik={formik} name="present_post" />
                        <MaskFormInput maskOption={{
                            mask: '000 0000000',
                        }} label="Official Number" formik={formik} name="place_of_work_official_number" />
                        <FormTextArea label="Office Address" formik={formik} name="office_address" />
                    </SimpleGrid>
                </SectionCard>
                <SectionCard backgroundColor="bg-[#ffffff]" title="Contact Information" p={5}>
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 4 }} spacing={3}>
                        <MaskFormInput maskOption={{
                            mask: '000 0000000',
                        }} label="Mobile Number" formik={formik} name="mobile_number" />
                        <MaskFormInput maskOption={{
                            mask: '000 0000000',
                        }} label="WhatsApp Number" formik={formik} name="whatsapp_number" />
                        <FormInput isDisabled label="Email" formik={formik} name="email" />
                        <FormTextArea label="Personal Address" formik={formik} name="personal_address" />
                    </SimpleGrid>
                </SectionCard>
                <SectionCard backgroundColor="bg-[#ffffff]" creatable={creatable} title="Educational Qualification"
                    p={5}>
                    <ChakraTable size={"sm"}
                        columns={EDUCATION_TABLE_COLUMNS}
                        data={_.get(formik.values, 'educational_qualifications', [])} />
                    <ChakraModal primaryAction={childFormik.submitForm} primaryButtonText={"Add"}
                        modalTitle={'Add new education qualification'} {...disclosure}>
                        <SimpleGrid columns={{ base: 1, md: 1, lg: 1, xl: 1 }} spacing={3}>
                            <FormDropdown isRequired options={levels} formik={childFormik}
                                name={'level'}
                                autoFocus
                                label={'Qualification Level'} />
                            <FormInput isRequired label="Title of Degree/Certificate" formik={childFormik} name="title" />
                            <FormNumberInput isRequired type="number" max={2050} min={1900} label="Year Achieved" formik={childFormik} name="obtained_year" />
                        </SimpleGrid>
                    </ChakraModal>
                </SectionCard>

                <SectionCard backgroundColor="bg-[#ffffff]" creatable={workExperienceCreatable} title="Work Experience"
                    p={5}>
                    <ChakraTable size={"sm"}
                        columns={WORK_EXPERIENCE_TABLE_COLUMNS}
                        data={_.get(formik.values, 'student_work_experience', [])} />
                    <ChakraModal primaryAction={workExperienceChildFormik.submitForm} primaryButtonText={"Add"}
                        modalTitle={selectRef.current ? ' Edit Work Experience' : 'Add new Work Experience'} {...workExperienceDisclosure} onClose={onCloseworkExperienceModal}>
                        <SimpleGrid columns={{ base: 1, md: 1, lg: 1, xl: 1 }} spacing={3}>
                            <FormInput isRequired label="Designation" formik={workExperienceChildFormik} name="designation" />
                            <FormInput isRequired label="Work Place" formik={workExperienceChildFormik} name="work_place" />
                            <FormInput type="date" isRequired label="From" formik={workExperienceChildFormik} name="from" />
                            <FormInput type="date" isRequired label="To" formik={workExperienceChildFormik} name="to" />
                        </SimpleGrid>
                    </ChakraModal>
                </SectionCard>

            </div>
        </div>
    )
}

export default StudentProfilePage

const gender = [{ value: "Male", label: "Male" }, { value: "Female", label: "Female" }]

const salutation = [
    { value: "Mr", label: "Mr" },
    { value: "Ms", label: "Ms" },
    { value: "Rev", label: "Rev" },
    { value: "Dr", label: "Dr" },
    { value: "Prof", label: "Prof" },
    { value: "Eng", label: "Eng" },
    { value: "Comm", label: "Comm" }
]

const levels = [
    { value: "A/L", label: "A/L" },
    { value: "Diploma", label: "Diploma" },
    { value: "Bachelor's Degree", label: "Bachelor's Degree" },
    { value: "Master's Degree", label: "Master's Degree" },
    { value: "Doctoral Degree", label: "Doctoral Degree" },
]
