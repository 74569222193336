import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, SimpleGrid } from "@chakra-ui/react"
import _ from "lodash"
import { FaRegCalendarTimes } from "react-icons/fa"
import { PiMonitorPlay } from "react-icons/pi"
import { TbTools } from "react-icons/tb"
import { TfiAnnouncement } from "react-icons/tfi"
import { Link, useParams } from "react-router-dom"
import { NOTIFICATION } from "../../../../consts/methods.consts"
import { useGetQuery } from "../../../../services/api.service"
import InnerLoading from "../../../common/inner-loading/InnterLoading"
import PageHeader from "../../../common/page-header-v2/PageHeader"

const NotificationViewPage = () => {
    const { id } = useParams()

    const { data, isLoading, isFetching } = useGetQuery({
        method: NOTIFICATION.GET,
        body: {
            id
        },
        providesTags: []
    }, { refetchOnMountOrArgChange: true })

    const _data = _.get(data, 'message', {})

    const breadcrumbMarkup = (
        <Breadcrumb  fontSize={{ base: 'sm', md: '24px' }} className="w-full block">
            <BreadcrumbItem className="block">
                <Link to={'/app/notifications'}>
                    <BreadcrumbLink display={'block'}>Notifications
                    </BreadcrumbLink>
                </Link>
            </BreadcrumbItem>
        </Breadcrumb>
    )

    const contentMarkup = (
        <Box p={5} borderRadius={15}  bg={'#ffffff'}>
            <div className=" text-[#2D3748]">
                {_data?.description}
            </div>
        </Box>
    )


    if (isLoading || isFetching) {
        return (
            <div>
                <div className="flex flex-col items-center">
                    <div className="container">
                        <div className="mt-6 space-y-10">
                            {breadcrumbMarkup}
                            <InnerLoading height={'72vh'} />
                        </div>
                    </div>
                </div >
            </div>
        )
    }

    return (
        <div className="flex flex-col items-center ">
            <div className="container mt-4">
                {breadcrumbMarkup}
                <div className="mt-8">
                    <PageHeader isLoading={isLoading || isFetching} title="Notification" subtitle={_.get(_data, 'title')} />
                    <SimpleGrid overflow={'scroll'} gridGap="8"
                        className="mt-8 h-[48vh] md:h-[50vh] lg:h-[53vh] overflow-auto"
                        columns={{ base: 1, md: 1, lg: 1 }} gap={{ base: '1', md: '1' }}>
                        {(isLoading || isFetching) ? <InnerLoading height={'75vh'} /> : contentMarkup}
                    </SimpleGrid>
                </div>
            </div>
        </div>
    )
}

export default NotificationViewPage


const iconByType = {
    "General": <TfiAnnouncement size={20} />,
    "Academic Deadline": <FaRegCalendarTimes size={20} />,
    "Workshop": <TbTools size={20} />,
    "Webinar": <PiMonitorPlay size={25} />
}

