import { NavigateFunction, useNavigate } from 'react-router-dom';

const PageNotFound = () => {
    const navigate: NavigateFunction = useNavigate();

    return (
        <div className="bg-white rounded-lg gap-20  px-20 flex flex-col lg:flex-row  items-center h-[80vh] ">
            <div className='md:w-[403px] lg:w-[403px]'>
                {image}
            </div>
            <div>
                <h1 className="text-5xl font-bold text-[#2D3748] mb-5">Ooops... </h1>
                <p className="text-[#2D374899] o text-xl">Sorry we couldn't find the page you're looking for</p>
                <button onClick={() => { navigate(-1) }} className='py-2 flex justify-center items-center hover:bg-black px-3 mt-4 bg-[#2D3748] rounded-md text-white'>Go Back</button>
            </div>
        </div>
    )
}

export default PageNotFound


const image = (
    <svg width="100%" height="299" viewBox="0 0 403 299" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M117.043 48.8845C117.043 48.8845 162.507 83.386 231.625 18.8744C293.009 -38.4166 343.13 51.9759 343.48 95.2641C343.934 151.338 282.097 196.205 312.107 233.035C342.115 269.866 252.592 330.685 204.344 278.05C144.324 212.574 128.064 265.774 93.8533 265.774C69.2998 265.774 18.8889 204.764 52.9308 159.374C81.5767 121.181 65.9537 108.505 58.3881 95.2641C47.4745 76.1662 73.3922 24.3311 117.043 48.8845Z" fill="#DCFFF5" />
        <path d="M299.568 125.127C302.012 126.036 304.682 126.096 307.223 125.512C317.869 123.069 354.473 116.599 348.113 144.964C346.728 150.592 325.29 182.11 355.82 193.614C358.058 194.468 360.423 194.934 362.817 194.992C372.218 195.186 390.845 192.966 398.641 185.031C408.059 175.444 397.77 179.99 397.77 179.99C397.77 179.99 361.966 195.614 349.319 179.037C347.912 177.193 347.016 174.514 346.76 172.208C346.314 168.21 348.089 162.927 350.421 158.326C353.578 152.101 368.732 124.098 335.83 115.94C328.251 114.135 317.242 112.515 303.048 118.49C294.768 121.976 296.602 124.025 299.568 125.127Z" fill="#BFFFEC" />
        <path d="M306.895 114.673L297.365 118.24C294.076 119.471 292.407 123.136 293.639 126.425L294.111 127.688C295.342 130.977 299.007 132.646 302.296 131.415L311.827 127.848C315.116 126.617 316.784 122.953 315.553 119.662L315.081 118.401C313.85 115.11 310.185 113.442 306.895 114.673Z" fill="#3AB48F" />
        <path d="M248.398 124.994L216.97 136.757C215.324 137.372 214.49 139.204 215.106 140.849L215.23 141.18C215.845 142.825 217.677 143.659 219.323 143.044L250.751 131.281C252.397 130.665 253.231 128.833 252.615 127.188L252.491 126.857C251.876 125.212 250.044 124.378 248.398 124.994Z" fill="white" />
        <path d="M257.17 148.431L225.741 160.192C224.096 160.808 223.262 162.641 223.878 164.285L224.001 164.617C224.617 166.261 226.45 167.096 228.094 166.479L259.523 154.718C261.168 154.102 262.002 152.269 261.387 150.625L261.263 150.293C260.648 148.649 258.814 147.814 257.17 148.431Z" fill="white" />
        <path d="M286.187 109L260.715 118.533C257.424 119.764 255.756 123.43 256.987 126.719L265.685 149.956C266.916 153.245 270.58 154.913 273.87 153.682L299.343 144.15C302.632 142.919 304.301 139.254 303.069 135.964L294.373 112.727C293.142 109.438 289.477 107.769 286.187 109Z" fill="#5DF6C7" />
        <path d="M263.347 115.095L237.873 124.629C234.584 125.86 232.916 129.525 234.147 132.815L244.523 160.537C245.754 163.826 249.418 165.495 252.707 164.264L278.181 154.732C281.47 153.5 283.138 149.836 281.907 146.545L271.531 118.823C270.3 115.533 266.636 113.864 263.347 115.095Z" fill="#64F1C6" />
        <path d="M267.334 109.47L267.134 109.544C264.941 110.365 263.828 112.809 264.649 115.001L279.185 153.838C280.005 156.031 282.448 157.144 284.641 156.323L284.841 156.247C287.035 155.427 288.146 152.984 287.325 150.791L272.791 111.955C271.971 109.762 269.527 108.649 267.334 109.47Z" fill="#2BC797" />
        <path d="M7.59841 134.299C7.59841 134.299 58.7557 119.923 51.3286 153.046C50.0387 158.287 31.4719 185.272 53.5877 198.228C58.3365 201.01 63.8167 202.242 69.3201 202.214C79.1872 202.162 94.8296 200.263 101.857 193.111C111.275 183.524 100.986 188.07 100.986 188.07C100.986 188.07 71.6055 200.156 56.4903 190.522C51.3292 187.233 48.9897 180.864 50.3912 174.905C51.1076 171.946 52.1984 169.09 53.6374 166.407C56.9246 160.25 71.9483 132.178 39.0459 124.02C31.4664 122.215 20.4579 120.595 6.26341 126.57C-7.93103 132.547 7.59841 134.299 7.59841 134.299Z" fill="#BFFFEC" />
        <path d="M108.736 180.658L99.206 184.225C95.9162 185.456 94.2475 189.121 95.4788 192.412L95.9512 193.673C97.1824 196.963 100.847 198.632 104.137 197.4L113.667 193.833C116.956 192.602 118.625 188.938 117.394 185.647L116.922 184.386C115.69 181.095 112.025 179.427 108.736 180.658Z" fill="#3AB48F" />
        <path d="M124.837 164.979L110.713 170.265C107.423 171.496 105.755 175.161 106.986 178.451L113.796 196.647C115.027 199.938 118.692 201.606 121.982 200.375L136.106 195.089C139.395 193.858 141.064 190.193 139.833 186.903L133.022 168.707C131.791 165.416 128.126 163.748 124.837 164.979Z" fill="#5DF6C7" />
        <path d="M148.349 150.969L127.287 158.852C123.997 160.083 122.329 163.748 123.56 167.038L133.935 194.76C135.167 198.051 138.831 199.719 142.121 198.488L163.183 190.605C166.473 189.374 168.142 185.709 166.911 182.419L156.535 154.697C155.304 151.406 151.639 149.737 148.349 150.969Z" fill="#64F1C6" />
        <path d="M152.811 145.228L152.612 145.302C150.417 146.123 149.306 148.567 150.127 150.76L164.661 189.597C165.481 191.789 167.926 192.902 170.118 192.081L170.318 192.006C172.511 191.185 173.624 188.742 172.803 186.548L158.267 147.713C157.447 145.52 155.004 144.407 152.811 145.228Z" fill="#2BC797" />
        <path d="M206.594 120.486C205.836 120.401 205.139 120.027 204.651 119.44C204.165 118.852 203.925 118.099 203.981 117.338L205.552 88.633C205.646 86.9184 207.094 85.6156 208.737 85.7672L211.027 85.9792C212.67 86.1308 213.846 87.6752 213.616 89.378L209.755 117.871C209.667 118.629 209.288 119.325 208.699 119.812C208.11 120.3 207.356 120.54 206.594 120.486Z" fill="#63E3BC" />
        <path d="M193.025 122.96C192.789 123.03 192.542 123.053 192.296 123.027C192.053 123 191.815 122.925 191.6 122.807C191.383 122.691 191.194 122.532 191.04 122.339C190.885 122.149 190.77 121.928 190.7 121.693L185.088 103.836C184.925 103.329 184.97 102.776 185.213 102.302C185.456 101.827 185.877 101.468 186.386 101.305L187.838 100.865C188.088 100.793 188.35 100.773 188.608 100.806C188.865 100.84 189.112 100.926 189.335 101.061C189.557 101.194 189.749 101.373 189.899 101.585C190.049 101.797 190.154 102.037 190.205 102.292L194.365 120.587C194.482 121.078 194.407 121.596 194.159 122.037C193.911 122.476 193.506 122.806 193.025 122.96Z" fill="#63E3BC" />
        <path d="M185.452 133.257C184.446 134.504 182.423 134.489 180.867 133.224L153.229 110.769C151.577 109.427 151.091 107.207 152.157 105.887L153.644 104.045C154.712 102.723 156.902 102.832 158.464 104.283L184.616 128.581C186.089 129.949 186.457 132.013 185.452 133.257Z" fill="#63E3BC" />
        <path d="M198.228 191.258C198.94 191.533 199.517 192.074 199.838 192.766C200.16 193.458 200.2 194.248 199.951 194.969L191.1 222.32C190.572 223.954 188.839 224.843 187.289 224.277L185.128 223.488C183.579 222.921 182.835 221.127 183.492 219.541L194.503 192.979C194.783 192.268 195.325 191.692 196.02 191.371C196.714 191.05 197.504 191.009 198.228 191.258Z" fill="#63E3BC" />
        <path d="M211.979 192.331C212.224 192.324 212.47 192.366 212.7 192.454C212.929 192.542 213.139 192.674 213.318 192.843C213.496 193.011 213.64 193.214 213.74 193.438C213.84 193.662 213.896 193.905 213.902 194.151L214.768 212.848C214.796 213.381 214.612 213.902 214.255 214.299C213.899 214.697 213.4 214.937 212.868 214.965L211.351 215.018C211.09 215.024 210.833 214.977 210.592 214.879C210.351 214.781 210.135 214.634 209.953 214.447C209.773 214.261 209.632 214.04 209.541 213.796C209.452 213.552 209.412 213.294 209.426 213.035L210.077 194.285C210.091 193.779 210.295 193.297 210.647 192.936C210.999 192.574 211.475 192.359 211.979 192.331Z" fill="#63E3BC" />
        <path d="M221.931 184.309C223.221 183.361 225.173 183.892 226.354 185.512L247.342 214.281C248.595 216 248.499 218.269 247.13 219.275L245.222 220.676C243.853 221.681 241.762 221.016 240.622 219.214L221.542 189.044C220.47 187.346 220.641 185.257 221.931 184.309Z" fill="#63E3BC" />
    </svg>

)