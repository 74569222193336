type SectionTitleProps = {
    title: string
}

const SectionTitle = ({ title }: SectionTitleProps) => {
    return (
        <div className="flex items-center justify-between py-12">
            <hr className="w-full border-gray-500 border-1" />
            <h1 className="text-2xl px-4 whitespace-nowrap font-bold">{title}</h1>
            <hr className="w-full border-gray-500 border-1" />
        </div>
    );
};

export default SectionTitle;