import { useRoutes } from "react-router-dom";
import { ExternalLayout, InternalLayout } from "../components/layouts";
import { HomePage, LoginPage } from "../components/pages";
import PageNotFound from "../components/pages/page-not-found/PageNotFound";
import StudentProfilePage from "../components/pages/profile/StudentProfilePage";
import AllProgramListPage from "../components/pages/all-courses/program-list-page/ProgramListPage";
import AllProgramModuleListPage from "../components/pages/all-courses/module-list-page/ModuleListPage";

import MylearningProgramListPage from "../components/pages/my-learning/program-list-page/ProgramListPage";
import MylearningModuleListPage from "../components/pages/my-learning/modue-list-page/ModuleListPage";

import ModuleListPage from "../components/pages/module/ModuleListPage";
import EventCalendarPage from "../components/pages/event-calendar/EventCalendarPage";
import ModuleViewPage from "../components/pages/all-courses/module-view-page/ModuleViewPage";
import NotificationLinePage from "../components/pages/notification/notification-list-page/NotificationListPage";
import MyLearningModuleViewPage from "../components/pages/my-learning/module-view-page/ModuleViewPage";
import NotificationViewPage from "../components/pages/notification/notification-view-page/NotificationViewPage";
import EventViewPage from "../components/pages/events/event-view-page/EventViewPage";
import FeedbackPage from "../components/pages/my-learning/feedback-page/feedback-page";
import RegistrationCompletePage from "../components/pages/registration-complete/RegistrationCompletePage";
import ResetPassword from "../components/pages/reset-password/ResetPassword";

const RouterConfigs = () => {

    return useRoutes([
        {
            path: "/",
            element: <ExternalLayout />,
            children: [
                {
                    index: true,
                    element: <LoginPage />
                },
            ]
        },
        {
            path: "/complete-registration/:key",
            element: <ExternalLayout />,
            children: [
                {
                    index: true,
                    element: <RegistrationCompletePage />
                },
            ]
        },
        {
            path: "/change-password/:key",
            element: <ExternalLayout />,
            children: [
                {
                    index: true,
                    element: <ResetPassword />
                },
            ]
        },
        {
            path: "/app",
            element: <InternalLayout />,
            children: [
                { index: true, element: <HomePage /> },
                {
                    path: ':id',
                    element: <MylearningModuleListPage />
                },
                {
                    path: 'events/',
                    element: <EventCalendarPage />
                },
                {
                    path: 'profile',
                    element: <StudentProfilePage />
                },
                {
                    path: 'my-learning',
                    children: [
                        { index: true, element: <MylearningProgramListPage /> },
                        {
                            path: ':id',
                            element: <MylearningModuleListPage />
                        },
                        {
                            path: 'module/:id',
                            element: <MyLearningModuleViewPage />
                        },
                        {
                            path: 'feedback/:id',
                            element: <FeedbackPage />
                        }
                    ]
                },
                {
                    path: 'all-programs',
                    children: [
                        { index: true, element: <AllProgramListPage /> },
                        {
                            path: ':id',
                            element: <AllProgramModuleListPage />
                        },
                        {
                            path: 'module/:id',
                            element: <ModuleViewPage />
                        },
                    ]
                },
                {
                    path: 'notifications',
                    element: <NotificationLinePage />
                },
                {
                    path: 'notifications/:id',
                    element: <NotificationViewPage />
                },
                {
                    path: 'events/:id',
                    element: <EventViewPage />
                },
                {
                    path: 'modules/:id/:user',
                    element: <ModuleListPage />
                }
            ],
        },
        { path: "*", element: <PageNotFound /> },
    ]);
};

export default RouterConfigs;

