import { Box, HStack, Heading, Stack, Text } from "@chakra-ui/react"

interface Props {
    label: string
    value: string
    delta?: {
        value: string
        isUpwardsTrend: boolean
    },
    className?: string
}

export const StatCard = (props: Props) => {
    const { label, value, delta, ...boxProps } = props
    const { className, ...restProps } = boxProps

    return (
        <Box
            className={`!rounded-2xl shadow hover:-translate-y-1 transition-all h-40 bg-gradient-to-r from-gray-50 to-gray-100 ${className}`}
            px={{ base: '4', md: '4' }}
            py={{ base: '4', md: '4' }}
            {...restProps}>
            <Stack className="h-full justify-center">
                <HStack justify="center">
                    <Text className="text-balck" fontSize="md" fontWeight={"normal"}>
                        {label}
                    </Text>
                </HStack>
                <HStack justify="center">
                    <Heading size={"xl"} className="text-black">{value}</Heading>
                </HStack>
            </Stack>
        </Box>
    )
}