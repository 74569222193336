import moment from "moment";

export const customeJsonParser = (str: any, def: any = '') => {
    try {
        return JSON.parse(str);
    } catch (e) {
        return def;
    }
}

export const getBase64 = (file: any, cb: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        cb(reader.result)
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
}


export const generateTime = (createdAt: any) => {
    try {
        const currentTime = moment();
        const timeDiffInMilliseconds = currentTime.diff(moment(createdAt));

        const duration = moment.duration(timeDiffInMilliseconds);

        let formattedTimeDiff = '';

        if (duration.asMinutes() < 1) {
            formattedTimeDiff = `${Math.floor(duration.asSeconds())} seconds ago`;
        } else if (duration.asHours() < 1) {
            formattedTimeDiff = `${Math.floor(duration.asMinutes())} minutes ago`;
        } else if (duration.asDays() < 1) {
            formattedTimeDiff = `${Math.floor(duration.asHours())} hours ago`;
        } else if (duration.asMonths() < 1 && duration.asYears() < 1) {
            formattedTimeDiff = `${Math.floor(duration.asDays())} days ago`;
        } else if (duration.asYears() < 1) {
            formattedTimeDiff = `${Math.floor(duration.asMonths())} months ago`;
        } else {
            formattedTimeDiff = `${Math.floor(duration.asYears())} years ago`;
        }

        return formattedTimeDiff;

    } catch {
        return createdAt;
    }
};
