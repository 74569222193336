import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, SimpleGrid, Text } from "@chakra-ui/react";
import { useGetQuery } from "../../../../services/api.service";
import { MY_LEARN } from "../../../../consts/methods.consts";
import _ from "lodash";
import ModuleDetailsCard from "./components/ModuleDetailsCard";
import { Link, useParams } from "react-router-dom";
import { MY_LEARN_TAGS } from "../../../../consts/tags.consts";
import PageHeader from "../../../common/page-header-v2/PageHeader";
import InnerLoading from "../../../common/inner-loading/InnterLoading";
import React, { JSX, useRef, useState } from "react"
import OverallProgress from "./components/OverallProgress";
import { FaAngleRight } from "react-icons/fa";
import EmptyResult from "../../../common/empty-result/emptyResult";
import FilterPanel from "../../../common/FilterPanle/FilterPanel";
import FilterSelectedTag from "../../../common/FilterSelectedTag/FilterSelectedTag";

const ModuleListPage = () => {
    const { id } = useParams()
    const [filterPanelData, setFilterPanelData] = useState({})
    const filterRef: any = useRef(() => { })

    const { data, isLoading, isFetching } = useGetQuery({
        method: MY_LEARN.GET_ENROLL_PROGRAM_MODULE,
        body: {
            program: id,
            ...filterPanelData
        },
        providesTags: [MY_LEARN_TAGS.LIST]
    })

    const { data: feedback_allowance } = useGetQuery({
        method: MY_LEARN.GET_FEEDBACK_ALLOWANCE,
        body: {
            program: id
        },
        providesTags: []
    })

    const onFilter = (result: any) => {
        setFilterPanelData(result)
    }

    const onCloseTag = (key: any, index: any) => {
        filterRef.current.removeFilter(key, index)
    }

    const _data: Array<any> = _.get(data, 'message.modules', [])
    const program_title = _.get(data, 'message.program_title', "")
    const percentage = _.get(data, 'message.percentage_of_completion', 0)
    const total_program_credit = _.get(data, 'message.total_program_credit', 0)
    const total_completed_module_credit = _.get(data, 'message.total_completed_module_credit', 0)
    const total_enroll_module_credit = _.get(data, 'message.total_enroll_module_credit', 0)
    const is_allow_feedback = _.get(feedback_allowance, 'message.allow_feedback', false)
    const percentage_of_enrolled_module_credit = _.get(data, 'message.percentage_of_enrolled_module_credit', 0)

    const contentMarkup: JSX.Element[] = _data.map((line: any, index: number) => (
        <ModuleDetailsCard key={index} code={line?.code} title={line?.title}
            type={line?.type} credit={line?.credit} completion={line?.completion}
            duration_days={line?.duration_days} isClosed={line?.is_closed}
            enrollment_state={line?.enrollment_state} totalProgramCredit={total_program_credit}
            program_module_id={line?.program_module_id}
            totalEnrollProgramCreditL={total_enroll_module_credit} />
    ))

    const BreadcrumbMarkup = (
        <Breadcrumb size={"sm"} className="w-full block"
            separator={<FaAngleRight className="font-extralight" color='gray.500' />}>
            <BreadcrumbItem className=" block  ">
                <Link to={'/app/my-learning'}>
                    <BreadcrumbLink display={'block'}>My Learning
                    </BreadcrumbLink>
                </Link>
            </BreadcrumbItem>
            <BreadcrumbItem className="block">
                <Link to={'#'}>
                    <BreadcrumbLink className=" truncate" display={'block'}>
                        {id}
                    </BreadcrumbLink>
                </Link>
            </BreadcrumbItem>
        </Breadcrumb>
    )

    const actionMarkup = (
        <div className="flex gap-4 w-[50%] items-center justify-end ">
            <FilterSelectedTag onCloseTag={onCloseTag} filter={filterPanelData} />
            <FilterPanel ref={filterRef} onFilter={onFilter} api={MY_LEARN.GET_MODULE_FILTER_PANEL_DATA} />
        </div>
    )

    const subTitleRenderer = (
        <div className="">
            <Text className="text-gray-600" fontSize={"sm"}>{program_title}</Text>
            {is_allow_feedback ? <div className="mt-2">
                <Link to={`/app/my-learning/feedback/${id}`} className="self-center  text-blue-500">Provide
                    Feedback</Link>
            </div> : undefined}

        </div>
    )

    return (

        <div className="flex flex-col items-center bg-gray-200">
            <div className="container mt-4">
                {BreadcrumbMarkup}
                <div className="mt-8">
                    <PageHeader isLoading={isLoading || isFetching} title="Program" subTitleRenderer={subTitleRenderer}
                        actionRenderer={actionMarkup} />
                    <OverallProgress percentage={percentage} total_program_credit={total_program_credit}
                        total_completed_module_credit={total_completed_module_credit}
                        total_enroll_module_credit={total_enroll_module_credit}
                        percentage_of_enrolled_module_credit={percentage_of_enrolled_module_credit} />
                    <SimpleGrid gridGap="8"
                        className="py-8 overflow-auto"
                        columns={{ base: 1, md: 2, lg: 3 }} gap={{ base: '4', md: '7' }}>
                        {_data.length === 0 ? <EmptyResult title="No Programs You
                            Enroll"/> : (isLoading || isFetching) ? <InnerLoading height={'75vh'} /> : contentMarkup}
                    </SimpleGrid>
                </div>
            </div>
        </div>
    )
}

export default ModuleListPage