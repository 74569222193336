import { useToast, Stack, FormLabel, HStack, Box, Text, Image } from "@chakra-ui/react"
import { Button } from "flowbite-react"
import { useFormik } from "formik"
import { useState } from "react"
import { NavigateFunction, useNavigate, useParams } from "react-router-dom"
import { usePostMutation } from "../../../../services/api.service"
import { ToastService } from "../../../../services/toast.service"
import { FormInput } from "../../../common/form-controllers/FormInput"
import * as Yup from "yup";
import loginBackground from "../../../../assets/images/login-background.png";
import { AUTH } from "../../../../consts/methods.consts"
import _ from "lodash"
import { customeJsonParser } from "../../../../utils/utils"

const ChangePasswordForm = (props: any) => {
    const navigator: NavigateFunction = useNavigate()
    const toast = new ToastService(useToast())
    const { key } = useParams()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [create] = usePostMutation();

    const formik = useFormik({
        initialValues: {
            pwd: "",
            confirm_pwd: "",
        },
        validationSchema: Yup.object({
            pwd: Yup.string().required("Email is required"),
            confirm_pwd: Yup.string().required("Password is required")
                .oneOf([Yup.ref("pwd"), ""], "Passwords must match")
        }),
        onSubmit: async (values: any) => {
            try {
                setIsLoading(true);
                await create({
                    method: AUTH.CHANGE_PASSWORD,
                    body: {
                        new_pwd: values.confirm_pwd,
                        key
                    },
                    invalidatesTags: []
                }).unwrap()
                navigator("/")
                toast.setTitle("Success").setDescription("Your Account has been successfully registred.").showSuccessToast();
            } catch (error: any) {
                let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
                if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
            } finally {
                setIsLoading(false)
            }
        }
    })

    const imageMarkup = (
        <Image src={loginBackground} className="object-cover" height={{ base: '45vw', md: '25vw' }}
            paddingX={{ base: '40px', md: '0px' }} alt="login page" />
    )

    return (
        <div className="bg-[#F8F9FF] md:bg-white flex flex-col h-full">
            <Box px={{ base: '30px', md: '0' }}
                py={{ base: '40px', md: '0' }} className={' flex items-center justify-center flex-1'}>
                <div className=' space-y-4 lg:space-y-2 lg:mb-[20px]'>
                    <div className="md:hidden mb-4">
                        {imageMarkup}
                    </div>
                    <Text
                        className={'font-[Poppins] text-opacity-80 text-gray-400 text-center lg:text-left font-normal text-xs lg:text-base leading-none'}>
                        Welcome
                    </Text>
                    <Text fontSize="3xl" fontWeight="bold" className="!mb-8" align={{ base: "center", md: "left" }}>
                        Create your password
                    </Text>
                    <form onSubmit={formik.handleSubmit}>
                        <Stack spacing="6">
                            <Stack spacing="4">
                                <Box>
                                    <FormLabel fontFamily={'Poppins'}
                                        className={'!font-[Poppins] !text-sm lg:!text-sm !font-medium text-[#5A5A5D]'}>Password</FormLabel>
                                    <FormInput size="md" fontFamily={'Poppins'} fontSize={{ base: '12px', md: '16px' }}
                                        _placeholder={{
                                            base: {
                                                color: '#BEBEBF',
                                                fontFamily: 'Poppins',
                                                fontStyle: 'normal'
                                            },
                                            md: {
                                                color: '#BEBEBF',
                                                fontFamily: 'Poppins',
                                                fontSize: '16px',
                                                fontStyle: 'normal'
                                            }
                                        }} placeholder="Enter your password"
                                        focusBorderColor="#7CA7FA38" borderWidth={'0.746px'} isRequired
                                        formik={formik} name='pwd' borderColor={"#BEBEBF"} type="password"
                                        borderRadius={'5.969px'} />
                                </Box>
                                <Box>
                                    <FormLabel fontFamily={'Poppins'}
                                        className={'!font-[Poppins] !text-sm lg:!text-sm !font-medium text-[#5A5A5D]'}>Confirm Password</FormLabel>
                                    <FormInput size="md" fontSize={{ base: '12px', md: '16px' }} fontFamily={'Poppins'}
                                        _placeholder={{
                                            base: { color: '#BEBEBF', fontStyle: 'normal' },
                                            md: { color: '#BEBEBF', fontFamily: 'Poppins', fontStyle: 'normal' }
                                        }} placeholder="Enter your password" type={'password'}
                                        borderWidth={'0.746px'} focusBorderColor="#7CA7FA38" isRequired
                                        formik={formik} name='confirm_pwd' borderColor={"#BEBEBF"}
                                        borderRadius={'5.969px'} />
                                </Box>


                            </Stack>
                            {/* <HStack justify="space-between">
                            <Checkbox defaultChecked>Remember me</Checkbox>
                            <Button variant="link" colorScheme="blue" size="sm">
                                Forgot password
                            </Button>
                        </HStack> */}
                            <Stack spacing="4">
                                <Button isLoading={isLoading} type={'submit'}
                                    className="!rounded-lg !font-[Poppins] !bg-[#4B89DC] !text-white hover:!bg-[#2a77de] !transition-all !text-base lg:!text-lg !font-semibold !leading-none">Submit</Button>
                            </Stack>
                        </Stack>
                    </form>
                </div>
            </Box>
        </div>
    )
}

export default ChangePasswordForm
