import { SimpleGrid, Text } from "@chakra-ui/react";
import { useFormik } from "formik";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { ALLPROGRAM } from "../../../../consts/methods.consts";
import { useGetQuery } from "../../../../services/api.service";
import EmptyResult from "../../../common/empty-result/emptyResult";
import { FormInput } from "../../../common/form-controllers/FormInput";
import InnerLoading from "../../../common/inner-loading/InnterLoading";
import ProgramDetailCard from "../../my-learning/program-list-page/components/ProgramDetailCard";
import FilterPanel from "../../../common/FilterPanle/FilterPanel";
import FilterSelectedTag from "../../../common/FilterSelectedTag/FilterSelectedTag";


const ProgramListPage = () => {
    const [filters, setFilters] = useState({})
    const [filterPanleData, setFilterPanelData] = useState({})
    const filterRef: any = useRef(() => { })

    const template = '{"category":"${_.get(filter,"category[0].value","")}" }'

    const { data, isLoading, isFetching } = useGetQuery({
        method: ALLPROGRAM.LISTPROGRAM,
        body: {
            ...filters,
            ...filterPanleData
        },
        providesTags: []
    })

    const programs: Array<any> = _.get(data, 'message', [])


    const formik = useFormik({
        initialValues: {},
        onSubmit: () => {
        }
    })

    useEffect(() => {
        onFilterHandler(formik.values)
    }, [JSON.stringify(formik.values)])

    const onFilterHandler = _.debounce((value: any) => {
        setFilters({ ...filters, ...value })
    }, 400)

    const programMarkup = programs.map((line: any, index: any) => (
        <ProgramDetailCard
            mode={line?.mode}
            program_id={line?.program_id}
            programName={line?.title}
            programCode={line?.code}
            noOfCredit={line?.credit}
            color={line?.color}
        />
    ))

    const contentMarkup = (
        programs.length === 0 ?
            <div className="mt-4">
                <EmptyResult title="Programs not found" />
            </div> :
            <SimpleGrid className="mt-8 bg-gray-200" gridGap="8" columns={{ base: 1, md: 2, lg: 3 }}
                gap={{ base: '4', md: '7' }}>
                {
                    programMarkup
                }
            </SimpleGrid>
    )

    const onFilter = (result: any) => {
        setFilterPanelData(result)
    }

    const onCloseTag = (key: any, index: any) => {
        filterRef.current.removeFilter(key, index)
    }

    return (
        <div className="flex flex-col items-center">
            <div className="container mt-4 mx-4">
                <div className="flex justify-between items-center p-6 bg-white mb-8 rounded-2xl shadow-sm">
                    <Text className="text-[1.3rem] font-bold">All Programs</Text>
                    <div className="flex gap-4 justify-between">
                        <FilterSelectedTag filter={filterPanleData} onCloseTag={onCloseTag} />
                        <FilterPanel ref={filterRef} width={{ base: '50px', md: '300px' }} body={template} onFilter={onFilter} api={ALLPROGRAM.GET_FILTER_PANEL_DATA} />
                    </div>
                </div>
                <div className="flex justify-between items-center">
                    <div className="w-72 bg-white rounded-md p-2">
                        <FormInput className="" placeholder="Search" formik={formik} name="q" />
                    </div>
                </div>

                {
                    (isFetching || isLoading) ? <InnerLoading height={'75vh'} /> : contentMarkup
                }

            </div>
        </div>
    )
}

export default ProgramListPage