import { Button, useToast } from "@chakra-ui/react"
import _ from "lodash"
import React from "react";
import { showConfirmDialog } from "../../../../../store/reducers/confirm-modal";
import { MY_LEARN } from "../../../../../consts/methods.consts";
import { MY_LEARN_TAGS } from "../../../../../consts/tags.consts";
import { customeJsonParser } from "../../../../../utils/utils";
import { AppDispatch } from "@dladio/hooks/dist/app/store";
import { useDispatch } from "react-redux";
import { ToastService } from "../../../../../services/toast.service";
import { usePostMutation } from "../../../../../services/api.service";
import { useParams } from "react-router-dom";

const GeneralInfoTabPanel = ({ data }: any) => {

    const dispatch: AppDispatch = useDispatch()
    const { id } = useParams()
    const toast = new ToastService(useToast());
    const [create, { isLoading }] = usePostMutation()

    const onMutationHandler = async () => {
        try {
            await create({
                method: MY_LEARN.REQUEST_MODULE,
                body: {
                    p_id: data?.program_id,
                    m_id: id
                },
                invalidatesTags: [MY_LEARN_TAGS.MODULE_VIEW]
            }).unwrap()

            toast.setTitle("Success").setDescription("Student has been created successfully.").showSuccessToast();
        } catch (e: any) {
            let message = customeJsonParser(_.get(e?.data, '_server_messages', {}))
            if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
        }
    }

    const programCredit = _.get(data, "program_credit", 0)
    const earnCredit = _.get(data, "earn_credit", -1)

    const onEnrollRequestHandler = () => {
        dispatch(
            showConfirmDialog({
                title: "Confirmation Required!",
                subtitle: "Are you sure you want to continue the action?",
                onConfirm: onMutationHandler
            })
        )
    }

    const requestStatusMapping: any = {
        "Not-Requested": <Button onClick={onEnrollRequestHandler} isLoading={isLoading} size="sm"
            isDisabled={programCredit <= earnCredit} className="!bg-gray-900 !text-white">Request
            Enroll</Button>,
        "Enrolled": <span
            className="bg-green-100 text-green-800 text-xs font-medium px-2.5 py-2 rounded">Enrolled</span>,
        "Pending": <span
            className="bg-yellow-100 text-green-800 text-xs font-medium px-2.5 py-2 rounded">Pending</span>,
        "Reject": <span
            className="bg-red-100 text-green-800 text-xs font-medium px-2.5 py-2 rounded">Reject</span>
    }

    return (
        <div className="shadow rounded-[15px] border">
            <div className="flex bg-white border-b w-full p-5 rounded-t-[15px]">
                <div className=" basis-1/5 flex justify-between pr-10">
                    <p className=" font-bold">Module Code

                    </p>
                    <p className="font-bold">:

                    </p>
                </div>
                <div className="">
                    <p className=" ">
                        {_.get(data, 'code') ?? "___"}

                    </p>
                </div>
            </div>
            <div className="flex w-full border-b bg-white p-5 ">
                <div className=" basis-1/5 flex justify-between pr-10">
                    <p className="font-bold">Module Name

                    </p>
                    <p>
                        :
                    </p>
                </div>
                <div>
                    <p className="">
                        {_.get(data, 'title') ?? "___"}
                    </p>
                </div>
            </div>
            <div className="flex w-full border-b bg-white p-5 ">
                <div className=" basis-1/5 flex justify-between pr-10">
                    <p className="font-bold">Assesment Criteria

                    </p>
                    <p>
                        :
                    </p>
                </div>
                <div>
                    <p className="">
                        {_.get(data, 'assessment_criteria') ?? "___"}
                    </p>
                </div>
            </div>
            <div className="flex w-full border-b bg-white p-5 ">
                <div className=" basis-1/5 flex justify-between pr-10">
                    <p className="font-bold">Type

                    </p>
                    <p>
                        :
                    </p>
                </div>
                <div>
                    {
                        _.get(data, 'type') === 'Elective' ?
                            <span
                                className="bg-[#6ee29a] bg-opacity-30 text-xs px-3 py-1 rounded-md text-[#053102] text-opacity-70">
                                {_.get(data, 'type')}
                            </span>
                            :
                            <span className="bg-[#DC4B4B] bg-opacity-20 text-xs px-3 py-1 rounded-md text-[#700]">
                                {_.get(data, 'type') ?? "___"}
                            </span>
                    }

                </div>
            </div>
            <div className="flex w-full border-b bg-white  p-5 ">
                <div className=" basis-1/5 flex justify-between pr-10">
                    <p className="font-bold">Duration

                    </p>
                    <p> :
                    </p>
                </div>
                <div>
                    <p className="">
                        {_.get(data, 'duration') ?? "___"}
                    </p>
                </div>
            </div>
            <div className="flex w-full border-b bg-white p-5 ">
                <div className=" basis-1/5 flex justify-between pr-10">
                    <p className="font-bold">Duration (Hours)

                    </p>
                    <p> :
                    </p>
                </div>
                <div>
                    <p className="">
                        {_.get(data, 'duration_hours')}
                    </p>
                </div>
            </div>
            <div className="flex w-full border-b bg-white  p-5 ">
                <div className=" basis-1/5 flex justify-between pr-10">
                    <p className="font-bold">Credit

                    </p>
                    <p> :
                    </p>
                </div>
                <div>
                    <p className="">
                        {_.get(data, 'credit') ?? "___"}
                    </p>
                </div>
            </div>
            <div className="flex w-full border-b bg-white p-5 ">
                <div className=" basis-1/5 flex justify-between pr-10">
                    <p className="font-bold">Group

                    </p>
                    <p> :
                    </p>
                </div>
                <div>
                    <p className="">
                        {_.get(data, 'group') ?? "___"}
                    </p>
                </div>
            </div>
            <div className="flex w-full bg-white p-5"
                style={styleMapping[data?.type]}>
                <div className=" basis-1/5 flex justify-between pr-10">
                    <p className="font-bold">Registration Closing Date

                    </p>
                    <p> :
                    </p>
                </div>
                <div>
                    <p className="">
                        {_.get(data, 'enrollment_closing_date') ?? "___"}
                    </p>
                </div>
            </div>
            {
                data.type === "Mandatory" ? null : <div className="flex w-full bg-white rounded-b-[15px] p-5 ">
                    <div className=" basis-1/5 flex justify-between pr-10">
                        <p className="font-bold">Enrollment Status

                        </p>
                        <p> :
                        </p>
                    </div>
                    <div>
                        {data?.request_status ?
                            <p className="">
                                {requestStatusMapping[data?.request_status]}
                            </p> : null}

                    </div>
                </div>
            }
        </div>
    )
}

const styleMapping: any = {
    "Mandatory": { borderBottomRightRadius: '15px', borderBottomLeftRadius: '15px' }
}

export default GeneralInfoTabPanel


