import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { hideConfirmDialog } from "../../../store/reducers/confirm-modal";

const ConfirmModal = () => {

    const dispatch = useDispatch()
    const { visible, model } = useSelector((state: any) => state.confirmDialog)

    const confirmHandler = () => {
        model?.onConfirm()
        dispatch(hideConfirmDialog())
    }

    return (
        <>
            <Modal
                isOpen={visible}
                motionPreset='slideInBottom'
                onClose={() => dispatch(hideConfirmDialog())}
                size={'md'}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{model?.title}</ModalHeader>
                    <ModalCloseButton autoFocus={false} _focus={{ border: 'none' }} />
                    <ModalBody pb={1}>
                        {model?.subtitle}
                    </ModalBody>

                    <ModalFooter>
                        <Button bg={'#2D3748'} _hover={{ backgroundColor: "black" }} size={'sm'} minW={'100px'} colorScheme="teal" onClick={confirmHandler} mr={3}>
                            Yes
                        </Button>
                        <Button size={'sm'} minW={'100px'} colorScheme="red" onClick={() => dispatch(hideConfirmDialog())}>No</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default ConfirmModal