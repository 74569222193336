import _ from "lodash"
import { TbTools } from "react-icons/tb"
import InnerLoading from "../../../common/inner-loading/InnterLoading"
import { SlEnvolopeLetter } from "react-icons/sl";
import { useGetQuery, usePostMutation } from "../../../../services/api.service"
import { customeJsonParser, generateTime } from "../../../../utils/utils"
import { ALLPROGRAM, NOTIFICATION } from "../../../../consts/methods.consts"
import { PiMonitorPlay } from "react-icons/pi"
import { TfiAnnouncement } from "react-icons/tfi"
import LengthPaginations from "../../../common/chakra-table/components/LengthPaginations"
import { FaRegCalendarTimes } from "react-icons/fa"
import { useEffect, useRef, useState } from "react"
import { FiArrowLeft, FiArrowRight } from "react-icons/fi"
import { Link, NavigateFunction, useNavigate } from "react-router-dom"
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Button, IconButton, Text, useToast } from "@chakra-ui/react"
import { NOTIFICATION_TAGS } from "../../../../consts/tags.consts";
import { showConfirmDialog } from "../../../../store/reducers/confirm-modal";
import { useDispatch } from "react-redux";
import { ToastService } from "../../../../services/toast.service";
import FilterPanel from "../../../common/FilterPanle/FilterPanel";
import FilterSelectedTag from "../../../common/FilterSelectedTag/FilterSelectedTag";

const NotificationLinePage = () => {
    const [create] = usePostMutation();
    const toast = new ToastService(useToast());
    const [pageLength, setLength] = useState(15)
    const [filter, setFilter] = useState({})
    const [page, setPage] = useState(15)
    const dispatch: any = useDispatch()
    const parentRef: any = useRef(() => { })
    const filterRef: any = useRef(() => { })

    const { data, isLoading, isFetching } = useGetQuery({
        method: NOTIFICATION.GET_LIST,
        body: {
            filter,
            pageLength,
            page
        },
        providesTags: [NOTIFICATION_TAGS.LIST]
    })

    const notificationList = _.isArray(_.get(data, 'message.data')) ? _.get(data, 'message.data', []) : []
    const paginationDetails = _.isArray(_.get(data, 'message.data')) ? _.get(data, 'message.meta', {}) : {}

    const breadcrumbMarkup = (
        <Breadcrumb fontSize={{ base: 'sm', md: '24px' }} className="w-full block">
            <BreadcrumbItem className="block">
                <Link to={'/app/notifications'}><BreadcrumbLink display={'block'}>Notifications</BreadcrumbLink></Link>
            </BreadcrumbItem>
        </Breadcrumb >
    )

    const onLength = (value: any) => {
        parentRef.current(1)
        setLength(value)
    }

    const onPageChange = (value: any) => {
        setPage(value)
    }

    const onFilter = (result: any) => {
        setPage(1)
        parentRef.current(1)
        setFilter(result)
    }

    const onCloseTag = (key: any, index: any) => {
        filterRef.current.removeFilter(key, index)
    }

    const markAllAsReadHandler = () => {
        dispatch(
            showConfirmDialog({
                title: "Confirmation Required!",
                subtitle: "Are you sure you want to continue the action?",
                onConfirm: () => {
                    create({
                        method: NOTIFICATION.MARK_ALL_AS_READ,
                        invalidatesTags: [NOTIFICATION_TAGS.LIST]
                    }).unwrap().then(() => {
                        toast.setTitle("Success").setDescription("All notifications have been marked as read.").showSuccessToast();
                    }).catch((error) => {
                        let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
                        if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
                    })
                }
            }))
    }

    const notificationContent = (
        <div>
            <div className="mt-3 max-h-[70vh] overflow-scroll bg-[#2D3748] bg-opacity-[0.02] rounded-[15px] opacity-2">
                {

                    notificationList?.map((line: any, index: any) => (
                        <NotificationLink
                            is_viewed={line?.is_viewed}
                            key={index}
                            _id={line?.id}
                            title={line?.title}
                            type={line?.type}
                            description={line?.description}
                            time={line?.time} />
                    ))

                }
            </div>
        </div>

    )

    const emptyNotificationMarkup = (
        <div className="flex gap-8 items-center justify-between px-8 hover:bg-slate-100 cursor-pointer py-3">
            <Text> No notifications at the moment</Text>
        </div>
    )

    if (isLoading || isFetching) {
        return (
            <div>
                <div className="flex flex-col items-center">
                    <div className="container">
                        <div className="mt-6 ">
                            {breadcrumbMarkup}
                            <div className="flex justify-between">
                                <div className="flex gap-2 justify-center items-center">
                                    <LengthPaginations customestyles={{ padding: '0px', width: '100px' }} onLength={onLength} totalResult={_.get(paginationDetails, 'total', 0)} />
                                    <Button  bg={'gray.500'} _hover={{ backgroundColor: "black" }} size={"sm"}
                                        borderRadius={'7px'} lineHeight={'normal'} textColor={'white'} onClick={markAllAsReadHandler}>
                                        Mark All as Read
                                    </Button>
                                </div>
                                <div className="flex gap-2 justify-center items-center">
                                    <FilterSelectedTag onCloseTag={onCloseTag} filter={filter} />
                                    <FilterPanel ref={filterRef} width={{ base: '50px', md: '300px' }} api={NOTIFICATION.GET_FILTER_OPTIONS} onFilter={onFilter} />
                                </div>
                            </div>

                            <InnerLoading height={'65vh'} />

                        </div>
                        <div className="flex justify-center items-center">
                            <TablePagination onPage={onPageChange} pageCount={_.get(paginationDetails, 'pageCount')} isLoading={isFetching} parentRef={parentRef} />
                        </div>
                    </div>
                </div >
            </div>
        )
    }


    return (
        <div>
            <div className="flex flex-col items-center">
                <div className="container">
                    <div className="mt-6 ">
                        {breadcrumbMarkup}

                        <div className="flex justify-between">
                            <div className="flex gap-2 justify-center items-center">
                                <LengthPaginations customestyles={{ padding: '0px', width: '100px' }} onLength={onLength} totalResult={_.get(paginationDetails, 'total', 0)} />
                                <Button className="" bg={'gray.500'} _hover={{ backgroundColor: "gray.600" }} size={"sm"}
                                    borderRadius={'7px'} lineHeight={'normal'} textColor={'white'} onClick={markAllAsReadHandler}>
                                    Mark All as Read
                                </Button>
                            </div>

                            <div className="flex gap-2 justify-center items-center">
                                <FilterSelectedTag onCloseTag={onCloseTag} filter={filter} />
                                <FilterPanel ref={filterRef} width={{ base: '50px', md: '300px' }} api={NOTIFICATION.GET_FILTER_OPTIONS} onFilter={onFilter} />
                            </div>
                        </div>

                        {
                            notificationList?.length > 0 ? notificationContent : emptyNotificationMarkup
                        }
                    </div>
                    <div className="flex justify-center items-center">
                        <TablePagination onPage={onPageChange} pageCount={_.get(paginationDetails, 'pageCount')} isLoading={isFetching} parentRef={parentRef} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotificationLinePage

const NotificationLink = ({ _id, title, type, is_viewed, time, description }: any) => {
    const navigate: NavigateFunction = useNavigate();
    const [create] = usePostMutation();

    const onClickLineHandler = () => {
        create({
            method: NOTIFICATION.MARK_AS_READ,
            body: { id: _id },
            invalidatesTags: [NOTIFICATION_TAGS.LIST]
        }).unwrap().then(() => {
            // @ts-ignore
        }).catch(() => {

        });
        navigate(`/app/notifications/${_id}`)
    }

    return (
        <div onClick={onClickLineHandler} className="flex gap-8 items-center rounded-[15px] justify-between px-3 hover:bg-[#2D3748] hover:bg-opacity-10 cursor-pointer py-3">
            <div className="flex gap-5 justify-center items-center">
                <div className="w-[50px] flex justify-center items-center bg-slate-200  h-[50px] rounded-full">
                    {_.get(iconByType, type)}
                </div>
                <div className="flex flex-col gap-2">
                    <p className="truncate max-w-[20vw] md:max-w-[50vw]">
                        {title}
                    </p>

                    <p className="text-[10px] opacity-40">
                        {generateTime(time)}
                    </p>
                </div>

            </div>

            <div> {
                is_viewed ? <div className="px-1">
                    <SlEnvolopeLetter className={' opacity-60'} size={18} />
                </div> :
                    (<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.89258 8L11.7967 13.26C12.1259 13.4793 12.5129 13.5963 12.9087 13.5963C13.3045 13.5963 13.6914 13.4793 14.0207 13.26L21.9248 8M5.89616 19H19.9212C20.4526 19 20.9622 18.7893 21.3379 18.4142C21.7137 18.0391 21.9248 17.5304 21.9248 17V7C21.9248 6.46957 21.7137 5.96086 21.3379 5.58579C20.9622 5.21071 20.4526 5 19.9212 5H5.89616C5.36477 5 4.85516 5.21071 4.47941 5.58579C4.10367 5.96086 3.89258 6.46957 3.89258 7V17C3.89258 17.5304 4.10367 18.0391 4.47941 18.4142C4.85516 18.7893 5.36477 19 5.89616 19Z" stroke="#2D3748" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>)
            }
            </div>
        </div>
    )
}



const TablePagination = ({ pageCount, isLoading, onPage, parentRef }: any) => {
    const [currentPage, setCurrentPage] = useState(1);

    const onNext = () => setCurrentPage(prev => prev + 1)

    const onPrevious = () => setCurrentPage(prev => prev !== 1 ? prev - 1 : prev)

    useEffect(() => { onPage && onPage(currentPage) }, [currentPage])

    const styles = {
        opacity: isLoading ? 0 : 1,
        height: isLoading ? 0 : 52
    }

    parentRef.current = setCurrentPage


    return (
        <div style={{ ...styles }}
            className="w-full px-4 transition-all duration-500 flex items-center gap-6 justify-center bg-gray-50">
            <p className="text-sm">{`Showing ${currentPage} of ${pageCount} page${pageCount > 1 ? 's' : ''}`}</p>

            <div className="flex items-center gap-3 lg:gap-2">
                <IconButton
                    onClick={onPrevious}
                    size={"sm"}
                    isDisabled={currentPage === 1}
                    variant="outline"
                    icon={<FiArrowLeft fontSize="1rem" />}
                    aria-label="Previous"
                />
                <IconButton
                    size={"sm"}
                    onClick={onNext}
                    isDisabled={currentPage >= pageCount}
                    variant="outline"
                    icon={<FiArrowRight fontSize="1rem" />}
                    aria-label="Next"
                />
            </div>
        </div>
    )
}

const iconByType = {
    "General": <TfiAnnouncement size={20} />,
    "Academic Deadline": <FaRegCalendarTimes size={20} />,
    "Workshop": <TbTools size={20} />,
    "Webinar": <PiMonitorPlay size={25} />
}