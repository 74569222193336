import * as Yup from "yup";
import { useState } from 'react';
import { FormInput } from '../../../common/form-controllers/FormInput';
import { useFormik } from 'formik';
import { ToastService } from '../../../../services/toast.service';
import { useLoginMutation, usePostMutation } from '../../../../services/api.service';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Box, Button, FormLabel, HStack, Image, Stack, StackProps, Text, useDisclosure, useToast } from '@chakra-ui/react';
import loginBackground from "../../../../assets/images/login-background.png";
import { AUTH } from "../../../../consts/methods.consts";
import { customeJsonParser } from "../../../../utils/utils";
import _ from "lodash";

export const SignInForm = (props: StackProps) => {
    const navigator: NavigateFunction = useNavigate()
    const toast = new ToastService(useToast())

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const isResetDisclosure = useDisclosure()
    const [create] = usePostMutation();
    const [login] = useLoginMutation();

    const formik = useFormik({
        initialValues: {
            usr: "",
            pwd: "",
            device: "mobile"
        },
        validationSchema: Yup.object({
            usr: Yup.string().required("Email is required"),
            pwd: Yup.string().required("Password is required")
        }),
        onSubmit: async (values: any) => {
            try {
                setIsLoading(true);
                let data = await login(values).unwrap();
                navigator("/app");

            } catch (error) {
                toast.setTitle("Error").setDescription("Invalid credentials. Try again").showErrorToast()
                console.error(error)
            } finally {
                setIsLoading(false)
            }
        }
    })

    const forgetPasswordformik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            user: Yup.string().required("Email is required")
        }),
        onSubmit: async (values: any) => {
            try {
                setIsLoading(true);
                await create({
                    method: AUTH.FORGOT_PASSWORD,
                    body: values,
                    invalidatesTags: []
                }).unwrap()
                toast.setTitle("Success").setTitle("Password reset link sent")
                    .setDescription("We have sent a password reset link to your email address. Please click on the link in the email to reset your password.")
                    .setDuration(4500).showSuccessToast();
                isResetDisclosure.onClose()
            } catch (error:any) {
                let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
            if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
            } finally {
                setIsLoading(false)
            }
        }
    })


    const imageMarkup = (
        <Image src={loginBackground} className="object-cover" height={{ base: '45vw', md: '25vw' }}
            paddingX={{ base: '40px', md: '0px' }} alt="login page" />
    )

    const loginFormMarkup: any = (
        <div className=' space-y-4 lg:space-y-2 lg:mb-[20px]'>
            <div className="md:hidden mb-4">
                {imageMarkup}
            </div>
            <Text
                className={'font-[Roboto] text-opacity-80 text-gray-400 text-center lg:text-left font-normal text-xs lg:text-base leading-none'}>
                Welcome back
            </Text>
            <Text fontSize="3xl" fontWeight="bold" className="!mb-8" align={{ base: "center", md: "left" }}>
                Login to your account
            </Text>
            <form onSubmit={formik.handleSubmit}>
                <Stack spacing="6">
                    <Stack spacing="4">
                        <Box>
                            <FormLabel fontFamily={'Roboto'}
                                className={'!font-[Roboto] !text-sm lg:!text-sm !font-medium text-[#5A5A5D]'}>Email</FormLabel>
                            <FormInput size="md" fontFamily={'Roboto'} fontSize={{ base: '12px', md: '16px' }}
                                _placeholder={{
                                    base: {
                                        color: '#BEBEBF',
                                        fontFamily: 'Roboto',
                                        fontStyle: 'normal'
                                    },
                                    md: {
                                        color: '#BEBEBF',
                                        fontFamily: 'Roboto',
                                        fontSize: '16px',
                                        fontStyle: 'normal'
                                    }
                                }} placeholder="example@slida.gov.lk"
                                focusBorderColor="#7CA7FA38" borderWidth={'0.746px'} isRequired
                                formik={formik} name='usr' borderColor={"#BEBEBF"}
                                borderRadius={'5.969px'} />
                        </Box>
                        <Box>
                            <FormLabel fontFamily={'Roboto'}
                                className={'!font-[Roboto] !text-sm lg:!text-sm !font-medium text-[#5A5A5D]'}>Password</FormLabel>
                            <FormInput size="md" fontSize={{ base: '12px', md: '16px' }} fontFamily={'Roboto'}
                                _placeholder={{
                                    base: { color: '#BEBEBF', fontStyle: 'normal' },
                                    md: { color: '#BEBEBF', fontFamily: 'Roboto', fontStyle: 'normal' }
                                }} placeholder="Enter your password" type={'password'}
                                borderWidth={'0.746px'} focusBorderColor="#7CA7FA38" isRequired
                                formik={formik} name='pwd' borderColor={"#BEBEBF"}
                                borderRadius={'5.969px'} />
                        </Box>


                    </Stack>
                    <HStack justify="space-between">
                        {/* <Checkbox defaultChecked>Remember me</Checkbox> */}
                        <Button variant="link" onClick={isResetDisclosure.onOpen} colorScheme="blue" size="sm">
                            Forgot password
                        </Button>
                    </HStack>
                    <Stack spacing="4">
                        <Button isLoading={isLoading} type={'submit'}
                            className="!rounded-lg !font-[Roboto] !bg-[#4B89DC] !text-white hover:!bg-[#2a77de] !transition-all !text-base lg:!text-lg !font-semibold !leading-none">Login
                            now</Button>
                    </Stack>
                </Stack>
            </form>
        </div>
    )


    const forgotPasswordMarkup: any = (
        <div className=' space-y-4 lg:space-y-2 lg:mb-[20px]'>
            <div className="md:hidden mb-4">
                {imageMarkup}
            </div>
            <Text
                className={'font-[Roboto] text-opacity-80 text-gray-400 text-center lg:text-left font-normal text-xs lg:text-base leading-none'}>
                Welcome back
            </Text>
            <Text fontSize="3xl" fontWeight="bold" className="!mb-8 pe-14" align={{ base: "center", md: "left" }}>
                Forgot Password
            </Text>
            <form onSubmit={forgetPasswordformik.handleSubmit}>
                <Stack spacing="6">
                    <Stack spacing="4">
                        <Box>
                            
                            <FormLabel fontFamily={'Roboto'}
                                className={'!font-[Roboto] !text-sm lg:!text-sm !font-medium text-[#5A5A5D]'}>Email</FormLabel>
                            <FormInput size="md" placeholder="example@slida.gov.lk" fontSize={{ base: '12px', md: '16px' }} fontFamily={'Roboto'} type='email'
                                _placeholder={{
                                    base: { color: '#BEBEBF', fontStyle: 'normal' },
                                    md: { color: '#BEBEBF', fontFamily: 'Roboto', fontStyle: 'normal' }
                                }}
                                name='user'
                                borderWidth={'0.746px'} focusBorderColor="#7CA7FA38" isRequired
                                formik={forgetPasswordformik} borderColor={"#BEBEBF"}
                                borderRadius={'5.969px'} />
                        </Box>
                    </Stack>
                    <HStack justify="space-between">
                        {/* <Checkbox defaultChecked>Remember me</Checkbox> */}
                        <Button onClick={isResetDisclosure.onClose} variant="link" colorScheme="blue" size="sm">
                            Back to Sign In
                        </Button>
                    </HStack>
                    <Stack spacing="4">
                        <Button isLoading={isLoading} type={'submit'}
                            className="!rounded-lg !font-[Roboto] !bg-[#4B89DC] !text-white hover:!bg-[#2a77de] !transition-all !text-base lg:!text-lg !font-semibold !leading-none">Submit</Button>
                    </Stack>
                </Stack>
            </form>
        </div>
    )

    if (!isResetDisclosure.isOpen) {
        return (
            <div key={1} className="animation-form-l3 animation-form-l3 bg-[#F8F9FF] md:bg-white flex flex-col h-full">
                <Box px={{ base: '30px', md: '0' }}
                    py={{ base: '40px', md: '0' }} className={' flex items-center justify-center flex-1'}>
                    <div className="animation-form-l3" key={1}>
                        {loginFormMarkup}
                    </div>
                </Box>
            </div>


        )
    }

    return (
        <div key={2} className=" animation-form-l3 bg-[#F8F9FF] md:bg-white flex flex-col h-full">
            <Box px={{ base: '30px', md: '0' }}
                py={{ base: '40px', md: '0' }} className={' flex items-center justify-center flex-1'}>
                <div className="animation-form-l3" key={1}>
                    {forgotPasswordMarkup}
                </div>
            </Box>
        </div>
    )

}
