import {
    Box,
    Flex,
    HStack,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    useBreakpointValue,
    useToast
} from "@chakra-ui/react";
import React from "react";
import { FiMenu, FiX } from "react-icons/fi";
import { IoIosArrowDown } from "react-icons/io";
import { useSelector } from "react-redux";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import { useLogoutMutation } from "../../../services/api.service";
import { ToastService } from "../../../services/toast.service";
import { usePathMetaProvider } from "../../hooks";
import { XceedLogo } from "../logo/Logo";
import { Menu as ProfileMenu } from "./components/Menu";
import Notifications from "./components/Notifications";

interface INavbarProps {
    isSideBarOpen: boolean,
    closeSideBar: () => void,
    openSideBar: () => void
}

interface INavLinkProps {
    path: string
    to: string,
    _activePath: string
    label: string
}

interface INavLinkWithChildrenProps {
    label: string,
    children: Array<any>
}

const Navbar = ({ isSideBarOpen, closeSideBar, openSideBar }: INavbarProps) => {
    const navigate: NavigateFunction = useNavigate();
    const activePath: string = usePathMetaProvider();
    const [logout, { }] = useLogoutMutation();
    const toast = new ToastService(useToast());
    const isMobile = useBreakpointValue<boolean>({ lg: true });

    const navConfig: Array<any> = [
        {
            label: "Home",
            path: "home",
            to: "/app"
        },
        {
            label: "My Learning",
            path: "my-learning",
            to: "my-learning"
        },
        {
            label: "Events",
            path: "events",
            to: "events"
        },
        {
            label: "Profile",
            path: "profile",
            to: "profile"
        },
        {
            label: "All Programs",
            path: "all-programs",
            to: "all-programs"
        }
    ]

    const logoutHandler = async () => {
        try {
            await logout({}).unwrap();

            navigate("/", { replace: true })
            window.location.reload()

        } catch (error) {
            console.error(error);
            toast.setTitle("Error").setDescription("Unable to logout. Something went wrong").showErrorToast();
        }
    }

    return (
        <Box left={0}
            className="flex flex-col items-center transition-all ease-in-out duration-200 bg-gray-50 fixed right-0 z-50"
            as="section"
            p={2} pr={{ base: 'auto', sm: '4' }}>
            <Flex className="container ps-1 flex items-center w-full justify-between">
                <HStack spacing={0} alignItems={"center"}>
                    <Box className="lg:hidden">
                        <IconButton
                            onClick={isSideBarOpen ? closeSideBar : openSideBar}
                            variant="ghost"
                            icon={isSideBarOpen ? <FiX fontSize="1.25rem" /> : <FiMenu fontSize="1.25rem" />}
                            aria-label="Open Menu"
                        />
                    </Box>
                    <XceedLogo />
                </HStack>
                <HStack spacing="4">
                    <div className="flex gap-8 items-end mr-2" hidden={!isMobile}>
                        {
                            navConfig.map((line: any, index: number) => {
                                if (line?.children) {
                                    return <NavLinkWithChildren key={index} label={line?.label}
                                        children={line?.children} />
                                } else {
                                    return <NavLink path={line?.path} _activePath={activePath} key={index} to={line?.to} label={line?.label} />
                                }
                            })
                        }
                    </div>
                    <Notifications />
                    <ProfileMenu />
                </HStack>
            </Flex>
        </Box>
    )
}

const NavLink: React.FC<INavLinkProps> = ({ to, label, _activePath, path }) => {
    let navStyle: any = {}

    if (_activePath === path) {
        navStyle = { color: '#000' }
    }

    return (
        <Link style={{ ...navStyle, 'letterSpacing': '0px' }} className={'hover:text-black text-gray-600 transition-all text-sm'} to={to}>{label}</Link>
    )
}

const NavLinkWithChildren: React.FC<INavLinkWithChildrenProps> = ({ label, children }) => {
    return (
        <Menu>
            <MenuButton>
                <div className="flex items-center gap-1">
                    <Text>
                        {label}
                    </Text>
                    <IoIosArrowDown />
                </div>
            </MenuButton>
            <MenuList>
                {children.map((line: any, index: number) => (
                    <MenuItem key={index} onClick={line?.onClick}>{line?.label}</MenuItem>
                ))}
            </MenuList>
        </Menu>
    )
}

export default Navbar;
