import { Text } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { BsCircle } from "react-icons/bs";

interface IProps {
    code: string,
    title: string,
    program_module_id: string,
    type: string,
    credit: string,
    duration_days: string,
    completion: string,
    enrollment_state: string,
    isClosed: boolean,
    totalProgramCredit: number,
    totalEnrollProgramCreditL: number
}

const ModuleDetailsCard: React.FC<IProps> = ({
    code,
    title,
    type,
    credit,
    duration_days,
    completion,
    program_module_id,
    enrollment_state,
    isClosed,
    totalEnrollProgramCreditL,
    totalProgramCredit
}) => {
    const moduleTypeColour: string = type === 'Mandatory' ? '#DC4B4B33' : '#D9DC4B33'
    const moduleTypeTextColour: string = type === 'Mandatory' ? '#770000' : '#53410099'
    const completionStatusColor: string = completion === 'Completed' ? '#4BDCB033' : '#F7F8FC'
    const completionStatusTxtColor: string = completion === 'Completed' ? '#007753' : '#565A6C'
    const enrollmentStateBgColor: string = enrollment_state === "Enrolled" ? '#4B89DC33' : '#F7F8FC'
    const enrollmentStateTxtColor: string = enrollment_state === "Enrolled" ? '#002177' : '#565A6C'

    const navigate = useNavigate()
    const isDisable: boolean = type === "Mandatory" ? false : (enrollment_state !== "Enrolled") ? (totalProgramCredit <= totalEnrollProgramCreditL) : false

    const onNavigationHandler = () => {
        navigate(`/app/my-learning/module/${program_module_id}`)
    }

    return (
        <div onClick={onNavigationHandler}
            style={{ backgroundColor: !isDisable ? '#FFFFFF' : '#e2e8f0' }}
            className="flex flex-col bg-white  max-h-[300px] box-border p-4 space-y-5 rounded-2xl shadow hover:-translate-y-1 cursor-pointer transition-all">

            <div className="space-y-2">
                <div className="flex justify-between items-center">
                    <Text className="text-md font-[600] text-[#2D3748]">{code}</Text>
                    <Text
                        className=" text-xs text-gray-700">Duration {duration_days} Days</Text>
                </div>
                <div className="flex gap-4">
                    <div>
                        <span style={{ backgroundColor: moduleTypeColour, color: moduleTypeTextColour }}
                            className="text-xs font-medium px-2.5 py-1 rounded-md">{type}</span>
                    </div>
                    <div>
                        <span
                            style={{ backgroundColor: completionStatusColor, color: completionStatusTxtColor }}
                            className="bg-green-100 text-green-800 text-xs font-medium px-2.5 py-1 rounded-md">{completion}</span>
                    </div>
                    <div>
                        <span style={{ backgroundColor: enrollmentStateBgColor, color: enrollmentStateTxtColor }}
                            className="text-xs font-medium px-2.5 py-1 rounded-md">{enrollment_state}</span>
                    </div>
                </div>
            </div>
            <div className="h-[50px] overflow-hidden">
                <Text className="line-clamp-3 text-ellipsis overflow-hidden text-md text-gray-700">{title}</Text>
            </div>
            <div className="flex justify-between items-center">
                <div className="flex justify-between items-end gap-2">
                    <div className="bg-[#dbf8ef] rounded-full p-1">
                        <BsCircle className="text-[#78e5c3]" size={15} />
                    </div>
                    <Text className="self-center text-sm text-gray-700">No of Credits {credit}</Text>
                </div>
                {isClosed ? <Text className="text-red-500">Module Closed</Text> : null}
            </div>
        </div>
    )
}

export default ModuleDetailsCard