export enum DISCLAIMER {
    LIST = "edpass.api.disclaimer.get_list",
    ADD = "edpass.api.disclaimer.add",
    GET = "edpass.api.disclaimer.get",
    UPDATE = "edpass.api.disclaimer.update",
}


export enum MODULE {
    LIST = "xceed.api.module.get_list",
    ADD = "xceed.api.module.add",
    GET = "xceed.student_portal.api.module.get_list",
    UPDATE = "xceed.api.module.update",
    ADD_NEW_CONTENT = "xceed.api.module.add_new_content",
    GET_LIST_FOR_DROPDOWN = "xceed.api.module.get_list_for_dropdown",
    DELETE = "xceed.api.module.delete",
}

export enum ALLPROGRAM {
    LISTPROGRAM = "xceed.student_portal.api.all_program.get_all_program_list",
    LISTMODULES = "xceed.student_portal.api.all_program.get_module_list_for_program",
    GETMODULEDETAILS = "xceed.student_portal.api.all_program.get_module",
    GET_SESSIONS = "xceed.student_portal.api.module.get_sessions",
    GET_FILTER_PANEL_DATA = "xceed.student_portal.api.all_program.filter_panel_data",
    GET_MODULE_FILTER_PANEL_DATA = "xceed.student_portal.api.all_program.module_filter_panel_data",
}

export enum STUDENT {
    LIST = "xceed.api.student.get_list",
    ADD = "xceed.api.student.add",
    GET = "xceed.api.student.get",
    UPDATE = "xceed.api.student.update",
    INIT = "xceed.api.student.init",
    DELETE = "xceed.api.student.delete",
    GET_SERVICES = "xceed.api.student.get_services",
}

export enum STUDENT_PROFILE {
    PROGRAM_SUMMARY = "xceed.api.student_profile.program_summary",
    SELECTED_PROGRAM_SUMMARY = "xceed.api.student_profile.selected_program_summary",
    ATTENDACE_SUMMARY = "xceed.api.student_profile.attendance_summary",
    MODULE_SUMMARY = "xceed.api.student_profile.module_summary",
    GET = "xceed.api.student.get",
    UPDATE = "xceed.api.student.update",
    INIT = "xceed.api.student.init",
    DELETE = "xceed.api.student.delete",
}

export enum CALENDAR {
    GET_EVENTS = "xceed.student_portal.api.event.get_list",
    GET = "xceed.student_portal.api.event.get",
    PROGRAMS = "xceed.api.calendar.get_programs",
    EVENTS = "xceed.api.calendar.get_events",
    SESSION_AGENDA = "xceed.api.calendar.get_sessions_for_agenda",
    PROGRAM_AGENDA = "xceed.api.calendar.get_programs_for_agenda"
}


export enum DASHBOARD {
    GET_UPCOMMING_PROGRAMS = "xceed.student_portal.api.dashboard.get_upcoming_programs",
    GET_ENROLLED_PROGRAMS = "xceed.student_portal.api.dashboard.get_enrolled_programs",
    GET_COMPLETED_PROGRAMS = "xceed.student_portal.api.dashboard.get_completed_programs",
    GET_STUDENT_COUNT_BY_YEAR = "xceed.api.dashboard.get_student_count_by_year",
}


export enum SIDEBAR {
    GET_LINKS = "xceed.api.sidebar.get_config",
    GET_USER_ROLES = "xceed.api.sidebar.get_user_and_roles"
}

export enum USER {
    GET = "xceed.student_portal.api.user.get",
    GET_USER_ENROLL_MODULES = "xceed.student_portal.api.user.get_user_enroll_modules_by_program"
}

export enum MY_LEARN {
    GET_ENROLL_PROGRAMS = "xceed.student_portal.api.my_learn.get_enrollment_programs",
    GET_ENROLL_PROGRAM_MODULE = "xceed.student_portal.api.my_learn.get_enrollment_modules",
    REQUEST_MODULE = "xceed.student_portal.api.module.enroll_module",
    GET_MODULE_DETAILS = "xceed.student_portal.api.my_learn.get_module_details",
    GET_FEEDBACK_ALLOWANCE = "xceed.student_portal.api.my_learn.is_allow_feedback",
    GIVE_FEEDBACK = "xceed.student_portal.api.my_learn.give_feedback",
    GET_PROGRAM_DETAILS = "xceed.student_portal.api.my_learn.get_program_details",
    GET_FILTER_PANEL_DATA = "xceed.student_portal.api.my_learn.filter_panel_data",
    GET_MODULE_FILTER_PANEL_DATA = "xceed.student_portal.api.my_learn.module_filter_panel_data"
}

export enum NOTIFICATION {
    GET_LIST_NAV = "xceed.student_portal.api.notification.get_list_for_nav",
    MARK_AS_READ = "xceed.student_portal.api.notification.mark_as_read",
    MARK_ALL_AS_READ = "xceed.student_portal.api.notification.mark_all_as_read",
    GET_LIST = "xceed.student_portal.api.notification.get_list",
    GET = "xceed.student_portal.api.notification.get",
    UNREAD_NOTIFICATION_COUNT = "xceed.student_portal.api.notification.unread_notification_count",
    GET_FILTER_OPTIONS = "xceed.student_portal.api.notification.filter_panel_data",
}


export enum AUTH {
    FORGOT_PASSWORD = "xceed.student_portal.api.auth.forgot_password",
    CHANGE_PASSWORD = "xceed.student_portal.api.auth.change_password"
}

