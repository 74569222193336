type HomeFooterBannerProps = {
    title: string,
    description: string
}

const HomePageHeroBanner = ({ title, description }: HomeFooterBannerProps) => {
    return (
        <div className="flex flex-col items-start justify-start mt-10">
            <h2 className="drop-shadow text-5xl font-extrabold leading-snug sm:text-6xl xl:text-6xl sm:leading-tight xl:leading-tighter text-center text-gray-900">{title}</h2>
            <p className="drop-shadow mx-auto mt-4 whitespace-pre-wrap max-w-4xl text-center text-xl leading-relaxed text-gray-600">{description}</p>
        </div>
    );
};

export default HomePageHeroBanner;