import { Text, useDisclosure } from "@chakra-ui/react";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { FaRegCalendarTimes } from "react-icons/fa";
import { PiMonitorPlay } from "react-icons/pi";
import { SlEnvolopeLetter } from "react-icons/sl";
import { TbTools } from "react-icons/tb";
import { TfiAnnouncement } from "react-icons/tfi";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import { NOTIFICATION } from "../../../../consts/methods.consts";
import { NOTIFICATION_TAGS } from "../../../../consts/tags.consts";
import apiService, { useGetQuery, usePostMutation } from "../../../../services/api.service";
import { generateTime } from "../../../../utils/utils";
import { useDispatch } from "react-redux";
import { callMethod } from "../../../../actions";
import InnerLoading from "../../inner-loading/InnterLoading";

const Notifications = () => {

    const ref: any = useRef(null)
    const [isVisibleMenu, setIsVisibleMenu] = useState(false)
    const dispatch: any = useDispatch()

    const { data, isLoading, isFetching } = useGetQuery({
        method: NOTIFICATION.GET_LIST_NAV,
        providesTags: [NOTIFICATION_TAGS.LIST]
    })

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        // @ts-ignore
        if (isVisibleMenu) dispatch(apiService.util.invalidateTags([NOTIFICATION_TAGS.LIST]))

    }, [isVisibleMenu])

    const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsVisibleMenu(false);
        }
    };
    const notificationList = _.isArray(_.get(data, 'message', [])) ? _.get(data, 'message', []) : []

    const emptyNotificationMarkup = (
        <div className="flex gap-8 items-center justify-between px-8 hover:bg-slate-100 cursor-pointer py-3">
            <Text> No notifications at the moment</Text>
        </div>
    )

    const notificationListMarkup = (
        <div className="max-h-[50vh] overflow-scroll">
            {
                notificationList.map((line: any, index: number) => (
                    <NotificationLineMarkup
                        is_viewed={line?.is_viewed}
                        visibility={setIsVisibleMenu}
                        key={index}
                        _id={line?.id}
                        title={line?.title}
                        type={line?.type}
                        description={line?.description}
                        time={line?.time} />
                ))
            }

        </div>

    )


    const menuMarkup = isVisibleMenu && (
        <div ref={ref}
            className="absolute right-[-70px] z-[10000] mt-4 w-[350px] md:w-[500px] origin-top-right rounded-[15px] bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-100">

            <div className="py-1">
                <div className="flex gap-2 px-10 py-3">
                    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.74973 0C6.76061 0 4.85296 0.790176 3.44643 2.1967C2.03991 3.60322 1.24973 5.51088 1.24973 7.5V11.9825L0.365984 12.8663C0.191222 13.0411 0.0722138 13.2638 0.0240044 13.5062C-0.0242051 13.7487 0.000548689 13.9999 0.0951362 14.2283C0.189724 14.4567 0.349898 14.6519 0.55541 14.7893C0.760923 14.9266 1.00255 14.9999 1.24973 15H16.2497C16.4969 14.9999 16.7385 14.9266 16.9441 14.7893C17.1496 14.6519 17.3097 14.4567 17.4043 14.2283C17.4989 13.9999 17.5237 13.7487 17.4755 13.5062C17.4273 13.2638 17.3082 13.0411 17.1335 12.8663L16.2497 11.9825V7.5C16.2497 5.51088 15.4596 3.60322 14.053 2.1967C12.6465 0.790176 10.7389 0 8.74973 0ZM8.74973 20C7.75517 20 6.80134 19.6049 6.09808 18.9017C5.39482 18.1984 4.99973 17.2446 4.99973 16.25H12.4997C12.4997 17.2446 12.1046 18.1984 11.4014 18.9017C10.6981 19.6049 9.7443 20 8.74973 20Z" fill="#2D3748" fillOpacity="0.6" />
                    </svg>
                    <p>
                        Notifications
                    </p>
                </div>
                <hr />
                <div>
                    {
                        notificationList?.length > 0 ? notificationListMarkup : emptyNotificationMarkup
                    }
                </div>
                <hr />

                <Link to={"notifications"}>
                    <Text
                        onClick={() => setIsVisibleMenu(false)}
                        width={'118px'}
                        className="hover:cursor-pointer hover:opacity-100  text-blue-500 opacity-60 px-8 py-3">
                        See All
                    </Text>
                </Link>
            </div>
        </div>
    )

    if (isLoading || isFetching) {

        return (
            <div tabIndex={0}
                className="relative inline-block text-left p-1 rounded-md hover:bg-gray-100">
                <div onClick={() => setIsVisibleMenu(prevState => !prevState)}>
                    <div className="w-full relative justify-center items-center hover:cursor-pointer gap-x-1.5">
                        {notificationIcon}
                        <NotificationCount />
                    </div>
                </div>
                {isVisibleMenu && (<div ref={ref}
                    className="absolute right-[-70px] z-[10000] mt-4 w-[350px] md:w-[500px] origin-top-right rounded-[15px] bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-100">

                    <div className="py-1">
                        <div className="flex gap-2 px-10 py-3">
                            <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.74973 0C6.76061 0 4.85296 0.790176 3.44643 2.1967C2.03991 3.60322 1.24973 5.51088 1.24973 7.5V11.9825L0.365984 12.8663C0.191222 13.0411 0.0722138 13.2638 0.0240044 13.5062C-0.0242051 13.7487 0.000548689 13.9999 0.0951362 14.2283C0.189724 14.4567 0.349898 14.6519 0.55541 14.7893C0.760923 14.9266 1.00255 14.9999 1.24973 15H16.2497C16.4969 14.9999 16.7385 14.9266 16.9441 14.7893C17.1496 14.6519 17.3097 14.4567 17.4043 14.2283C17.4989 13.9999 17.5237 13.7487 17.4755 13.5062C17.4273 13.2638 17.3082 13.0411 17.1335 12.8663L16.2497 11.9825V7.5C16.2497 5.51088 15.4596 3.60322 14.053 2.1967C12.6465 0.790176 10.7389 0 8.74973 0ZM8.74973 20C7.75517 20 6.80134 19.6049 6.09808 18.9017C5.39482 18.1984 4.99973 17.2446 4.99973 16.25H12.4997C12.4997 17.2446 12.1046 18.1984 11.4014 18.9017C10.6981 19.6049 9.7443 20 8.74973 20Z" fill="#2D3748" fillOpacity="0.6" />
                            </svg>
                            <p>
                                Notifications
                            </p>
                        </div>
                        <hr />
                        <div>
                            <div className="min-h-[50vh] flex justify-center items-center w-full overflow-scroll">
                                <InnerLoading height={'100%'} />
                            </div>
                        </div>
                    </div>
                </div>)
                }
            </div>

        )
    }

    return (
        <div tabIndex={0}
            className="relative inline-block text-left p-1 rounded-md hover:bg-gray-100">
            <div onClick={() => setIsVisibleMenu(prevState => !prevState)}>
                <div className="w-full relative justify-center items-center hover:cursor-pointer gap-x-1.5">
                    {notificationIcon}
                    <NotificationCount />
                </div>
            </div>
            {menuMarkup}
        </div>
    )
}

export default Notifications


const NotificationLineMarkup = ({ visibility, _id, is_viewed, title, type, time, description }: any) => {
    const navigate: NavigateFunction = useNavigate();
    const [create] = usePostMutation();
    const dispatch: any = useDispatch()

    const onClickLineHandler = () => {
        create({
            method: NOTIFICATION.MARK_AS_READ,
            body: { id: _id },
            invalidatesTags: [NOTIFICATION_TAGS.LIST]
        }).unwrap()
        navigate(`/app/notifications/${_id}`)
        visibility(false)
    }

    return (
        <div onClick={onClickLineHandler} className="flex gap-8 items-center justify-between px-8 hover:bg-slate-100 cursor-pointer py-3 text-md">

            <div className="flex gap-5 justify-center items-center">
                <div className="w-[50px] flex justify-center items-center bg-slate-200  h-[50px] rounded-full">
                    {_.get(iconByType, type)}
                </div>
                <div className="flex flex-col gap-2">
                    <p className=" truncate max-w-[20vw] md:max-w-[20vw] xl:max-w-[15vw]">
                        {title}
                    </p>

                    <p className="text-[10px] opacity-40">
                        {generateTime(time)}
                    </p>
                </div>

            </div>


            <div> {
                is_viewed ? <div className="p-1">
                    <SlEnvolopeLetter className={' opacity-60'} size={18} />
                </div> :
                    (<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.89258 8L11.7967 13.26C12.1259 13.4793 12.5129 13.5963 12.9087 13.5963C13.3045 13.5963 13.6914 13.4793 14.0207 13.26L21.9248 8M5.89616 19H19.9212C20.4526 19 20.9622 18.7893 21.3379 18.4142C21.7137 18.0391 21.9248 17.5304 21.9248 17V7C21.9248 6.46957 21.7137 5.96086 21.3379 5.58579C20.9622 5.21071 20.4526 5 19.9212 5H5.89616C5.36477 5 4.85516 5.21071 4.47941 5.58579C4.10367 5.96086 3.89258 6.46957 3.89258 7V17C3.89258 17.5304 4.10367 18.0391 4.47941 18.4142C4.85516 18.7893 5.36477 19 5.89616 19Z" stroke="#2D3748" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>)
            }
            </div>

        </div>
    )
}

const NotificationCount = () => {

    const { data, isLoading, isFetching } = useGetQuery({
        method: NOTIFICATION.UNREAD_NOTIFICATION_COUNT,
        providesTags: [NOTIFICATION_TAGS.LIST]
    })

    const count = _.get(data, 'message')

    return (
        count ? (<div className="w-[20px] z-50 flex justify-center items-center h-[20px] top-[-10px] right-[-15px] absolute text-white text-[12px] bg bg-red-700 rounded-full">
            {count}
        </div>) : null
    )
}


const notificationIcon = (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.74973 0C6.76061 0 4.85296 0.790176 3.44643 2.1967C2.03991 3.60322 1.24973 5.51088 1.24973 7.5V11.9825L0.365984 12.8663C0.191222 13.0411 0.0722138 13.2638 0.0240044 13.5062C-0.0242051 13.7487 0.000548683 13.9999 0.0951362 14.2283C0.189724 14.4567 0.349898 14.6519 0.55541 14.7893C0.760923 14.9266 1.00255 14.9999 1.24973 15H16.2497C16.4969 14.9999 16.7385 14.9266 16.9441 14.7893C17.1496 14.6519 17.3097 14.4567 17.4043 14.2283C17.4989 13.9999 17.5237 13.7487 17.4755 13.5062C17.4273 13.2638 17.3082 13.0411 17.1335 12.8663L16.2497 11.9825V7.5C16.2497 5.51088 15.4596 3.60322 14.053 2.1967C12.6465 0.790176 10.7389 0 8.74973 0ZM8.74973 20C7.75517 20 6.80134 19.6049 6.09808 18.9017C5.39482 18.1984 4.99973 17.2446 4.99973 16.25H12.4997C12.4997 17.2446 12.1046 18.1984 11.4014 18.9017C10.6981 19.6049 9.7443 20 8.74973 20Z" fill="#2D3748" />
    </svg>

)

const iconByType = {
    "General": <TfiAnnouncement size={20} />,
    "Academic Deadline": <FaRegCalendarTimes size={20} />,
    "Workshop": <TbTools size={20} />,
    "Webinar": <PiMonitorPlay size={25} />
}