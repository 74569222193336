import { Box, useTab, Text } from "@chakra-ui/react"
import React from "react"

const CustomTab = React.forwardRef((props: any, ref: any) => {
    const tabProps = useTab({ ...props, ref })
    const isSelected = !!tabProps['aria-selected']

    return (
        <Box  _focus={{border:'None'}} _hover={{ cursor: 'pointer' }} {...tabProps} role='none' onFocus={()=>{}}>
            {isSelected ?
                <Text _focus={{border:'None'}} color={'#2D3748'} fontSize={'16px'} fontWeight={600} >{tabProps.children}</Text> :
                <Text _focus={{border:'None'}} opacity={60} fontSize={'16px'} fontWeight={400} >{tabProps.children}</Text>
            }
        </Box>
    )
})

export default CustomTab