import { Box, Tag, TagCloseButton, TagLabel } from "@chakra-ui/react"
import _ from "lodash"

const FilterSelectedTag = ({ filter = {}, onCloseTag = () => { } }: any) => {

    const tagsSection = (
        <Box className="hidden md:flex flex-wrap gap-2 justify-center items-center">
            {_.size(Object.keys(filter)) > 0 && (Object.keys(filter).map((key: any, index: any) => {
                return (<SelectionTags filter={filter} parentSetKey={key} onCloseTag={onCloseTag} />)
            }))}
        </Box>
    )

    return (
        tagsSection
    )
}

export default FilterSelectedTag

const SelectionTags = ({ filter, parentSetKey, onCloseTag }: any) => {
    const appliedFilters = filter[parentSetKey]

    if (_.isArray(appliedFilters)) {
        return (
            <>
                {
                    appliedFilters.map((item: any, index: any) => {
                        return (<Ctag index={index} value={item} optionKey={parentSetKey} onCloseTag={onCloseTag} />)
                    })}

            </>
        )
    }

    return null
}

const Ctag = ({ onCloseTag, value, index, optionKey }: any) => {

    return (
        <Tag
            p={1}
            size={'sm'}
            key={index}
            className="rounded-md"
            variant='solid'
            backgroundColor={'gray.200'}
        >
            <TagLabel className=" opacity-70 text-black text-xs" ps={2}>{
                value?.label
            }</TagLabel>
            <TagCloseButton color={"black"} pe={2} onClick={() => onCloseTag(optionKey, index)} />
        </Tag>
    )
}
