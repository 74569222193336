import { Box } from "@chakra-ui/react"
import _ from "lodash"
import EmptyResult from "../../../../common/empty-result/emptyResult";

const Mythology = ({ data }: any) => {

    const ListMarkUp = data?.length > 0 ? (
        data?.map((line: any, index: number) => (
            <li className="mt-4" key={index}>
                {_.get(line, 'description')}
            </li>
        ))
    ) : (<h1>No Data</h1>)


    return (
        <>
            {
                ListMarkUp.length > 0 ? (
                    <Box p={5} className="shadow" borderRadius={15} bg={'#ffffff'}>
                        <ul className="px-5 list-outside list-disc text-[#2D3748]">
                            {
                                ListMarkUp
                            }
                        </ul>
                    </Box>
                ) : <EmptyResult title={"No Data"} />
            }
        </>
    )

}

export default Mythology