import React from "react";
import { Image, Text } from "@chakra-ui/react";
import emptyResult from "../../../assets/images/empty-result.png"
import { FiAlertTriangle } from "react-icons/fi";

interface IProps {
    title: string;
    showSymbol?: boolean
}

const EmptyResult: React.FC<IProps> = ({ title, showSymbol = true }) => {

    return (
        <div className="flex justify-start items-center content-center space-x-2">
            {showSymbol && <FiAlertTriangle className="text-red-800" />}
            <Text className="text-red-800">{title}</Text>
        </div>
    )
}

export default EmptyResult