import { FormControl, FormErrorMessage, FormLabel, Input, InputGroup } from "@chakra-ui/react";
import _ from "lodash";
import { useEffect } from "react";
import { useIMask } from "react-imask";
import { FormInputProps } from "./FormInput";

interface MaskFormInput extends FormInputProps {
    maskOption: Object
}

const MaskFormInput = ({ rounded = "md", size = 'sm', customMessage, type = 'text', formik, isRequired = false, name, label, containerClassNames = '', maskOption, ...restProps }: MaskFormInput) => {

    const formikValue = _.get(formik.values, name) ? _.get(formik.values, name) : ''

    const onAccept = (value: any, maskRef: any) => {
        formik.setFieldValue(name, _.get(maskRef, '_unmaskedValue', ''))
    }

    const {
        ref,
        value,
        setUnmaskedValue,
    } = useIMask(maskOption, { onAccept });


    useEffect(() => {
        setUnmaskedValue(_.get(formik.values, name, ''))
    }, [formikValue])

    return (
        <FormControl isRequired={isRequired} className={containerClassNames} isInvalid={Boolean(formik.submitCount && formik.errors[name])}>
            {label && <FormLabel className={'!opacity-75 !text-[13px]'}>{label}</FormLabel>}
            <InputGroup size='md'>
                <Input ref={ref} type={type} _ shadow={"sm"} borderColor={'gray.300'} size={size} {...restProps} name={name} value={value} rounded={rounded} />
            </InputGroup>
            {(formik.submitCount && formik.errors[name]) ? <FormErrorMessage mt={0}>{formik.errors[name]}</FormErrorMessage> : customMessage && customMessage}
        </FormControl>
    )

}
export default MaskFormInput