import { useBreakpointValue } from "@chakra-ui/react";
import { useEffect, useState } from "react";

interface IUseSideBar {
    isSideBarOpen: boolean,
    openSideBar: () => void,
    closeSideBar: () => void
}

export const useSideBar = (): IUseSideBar => {
    const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false)

    const openSideBar = () => setIsSideBarOpen(true);
    const closeSideBar = () => setIsSideBarOpen(false);

    return { isSideBarOpen, openSideBar, closeSideBar }
}