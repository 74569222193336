import { Text } from "@chakra-ui/react";
import { SlBadge } from "react-icons/sl";
import React from "react";
import { Badge } from "flowbite-react";

interface IProps {
    moduleName: string,
    duration: string,
    moduleCode: string,
    noOfCredit: string,
    type: string
}

const ModuleDetailCard: React.FC<IProps> = ({ moduleName, moduleCode, noOfCredit, duration, type }) => {

    return (
        <div
            className="flex flex-col border mt-4 p-2 sm:p-4 rounded-md shadow-sm bg-gray-50">
            <div className="flex justify-between">
                <Text className="mt-3 text-justify">{moduleCode}</Text>
                <Text className="mt-3 text-justify">{duration}</Text>
            </div>
            <Text className="my-4">{moduleName}</Text>
            <div className="flex justify-between">
                <div className="self-end bottom-2 sm:bottom-4 flex gap-2">
                    <SlBadge size={20} />
                    <Text>No of Credits {noOfCredit}</Text>
                </div>
                <Badge className={type === "Mandatory" ? "bg-yellow-200" : "bg-purple-300"}>{type}</Badge>
            </div>
        </div>
    )
}

export default ModuleDetailCard