import { Spinner } from "@chakra-ui/react"

const InnerLoading = ({height="100%"}:any) => {
    return (
        <div style={{height:height}} className=" w-full flex justify-center items-center">
            <Spinner size={"md"} />
        </div>
    )
}

export default InnerLoading