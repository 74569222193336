import { Box, SimpleGrid } from "@chakra-ui/react";
import _ from "lodash";
import { StatCard } from "../..";
import { DASHBOARD } from "../../../consts/methods.consts";
import { useGetQuery } from "../../../services/api.service";
import Announcements from "../../common/Announcements";
import HomeFooterBanner from "../../common/HomeFooterBanner";
import HomePageHeroBanner from "../../common/HomePageHeroBanner";
import LatestNews from "../../common/LatestNews";
import UpcommingPrograms from "../../common/UpcommingPrograms";

const HomePage = () => {

    const { data, isLoading, isFetching } = useGetQuery({
        method: DASHBOARD.GET_UPCOMMING_PROGRAMS,
        providesTags: []
    })

    const {
        data: enrolledPrograms,
    } = useGetQuery({
        method: DASHBOARD.GET_ENROLLED_PROGRAMS,
        providesTags: []
    })

    const {
        data: completedPrograms,
    } = useGetQuery({
        method: DASHBOARD.GET_COMPLETED_PROGRAMS,
        providesTags: []
    })

    const programs: Array<any> = _.get(data, 'message', [])
    const numberOfEnrolledPrograms = _.get(enrolledPrograms, 'message', 0)
    const numberOfCompletedPrograms = _.get(completedPrograms, 'message', 0)

    return (
        <div className="flex flex-col mt-6 items-center">

            <HomePageHeroBanner
                title="Welcome to CAMS Student Portal"
                description="Welcome to the SLIDA CAMS Student Portal! We are thrilled to have you join our dynamic and vibrant community. This portal serves as your gateway to a variety of resources, tools, and information designed to enhance your academic journey.
                Here, you will find a user-friendly interface that empowers you to manage your academic profile, access course materials, academic grades and SLIDA upcoming Programs The CAMS Student Portal is not just a virtual space; it's a hub for collaboration, learning, and growth."
            />

            <div className="container mt-20">
                {/* <SimpleGrid columns={{ base: 1, md: 4 }} gap={{ base: '4', md: '6' }}>
                    <StatCard label={"Enrolled Programs"} value={numberOfEnrolledPrograms} className={"bg-white "} />
                    <StatCard label={"Completed Programs"} value={numberOfCompletedPrograms} className={"bg-white"} />
                    <StatCard label={"NEEDS TO BE PROVIDED"} value={'10'} className={"bg-white"} />
                    <StatCard label={"NEEDS TO BE PROVIDED"} value={'11'} className={"bg-white"} />
                </SimpleGrid> */}
                <Box display={'flex'} width={'full'} justifyContent={'center'}>
                    <SimpleGrid width={'50%'} columns={{ base: 1, md: 2 }} gap={{ base: '4', md: '6' }}>
                        <StatCard label={"Enrolled Programs"} value={numberOfEnrolledPrograms} className={"bg-white "} />
                        <StatCard label={"Completed Programs"} value={numberOfCompletedPrograms} className={"bg-white"} />
                    </SimpleGrid>
                </Box>

                <div className="mt-20" />

                <UpcommingPrograms />
                <LatestNews />
                <Announcements />

                <HomeFooterBanner />
            </div>
        </div>
    )
}

export default HomePage;
