import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Skeleton,
    TabList,
    TabPanel,
    TabPanels,
    Tabs
} from '@chakra-ui/react';
import { Link, useParams } from 'react-router-dom';
import React from 'react';
import CustomTab from './components/CustomTab';
import GeneralInfoTabPanel from './components/GeneralInfoTabPanel';
import ModuleContent from './components/ModuleContent';
import LerningOutComes from './components/LerningOutComes';
import SpecificObjectives from './components/SpecificObjectives';
import Methodology from './components/Methodology';
import { MY_LEARN } from '../../../../consts/methods.consts';
import { useGetQuery } from '../../../../services/api.service';
import _ from 'lodash';
import Session from "./components/session/Session";
import { InnerLoading } from "../../../index";
import { MY_LEARN_TAGS } from "../../../../consts/tags.consts";
import { FaAngleRight } from 'react-icons/fa';

const MyLearningModuleViewPage = () => {
    const { id } = useParams()

    const { data, isLoading, isFetching } = useGetQuery({
        method: MY_LEARN.GET_MODULE_DETAILS,
        body: {
            id
        },
        providesTags: [MY_LEARN_TAGS.MODULE_VIEW]
    })

    const _data = _.get(data, 'message', [])
    const title = _.get(_data, 'general_info.title', "")
    const learningOutcomes = _.get(_data, 'learning_outcomes', [])
    const moduleContent = _.get(_data, 'module_content', [])
    const moduleMethodology = _.get(_data, 'module_methodology', [])
    const specificObjectives = _.get(_data, 'specific_objectives', [])
    const GeneralInfo = _.get(_data, 'general_info', {})
    const program_id = _.get(_data, 'general_info.program_id', '')


    const tabMarkUp = (
        <Tabs minW={'150px'} className={"mt-8"} overflow={'scroll'}>
            <TabList
                className="shadow !border-none text-gray-500 !flex-col !items-center !gap-2 sm:!gap-6 sm:!flex-row"
                minW={'150px'} mb={6} gap={10}
                bgColor={'#ffffff'} padding={5}
                borderRadius={'15px'}>
                <CustomTab border={'0px'}>General info</CustomTab>
                <CustomTab border={'0px'}>Sessions</CustomTab>
                <CustomTab border={'0px'}>Content</CustomTab>
                <CustomTab border={'0px'}>Specific Objectives</CustomTab>
                <CustomTab border={'0px'}>Learning outcomes</CustomTab>
                <CustomTab border={'0px'}>Methodology</CustomTab>
            </TabList>
            <TabPanels>
                <TabPanel className='!p-0 !m-0' px={0}>
                    <GeneralInfoTabPanel data={GeneralInfo} />
                </TabPanel>
                <TabPanel className='!p-0 !m-0' px={0}>
                    <Session />
                </TabPanel>
                <TabPanel className='!p-0 !m-0' px={0}>
                    <ModuleContent data={moduleContent} />
                </TabPanel>
                <TabPanel className='!p-0 !m-0' px={0}>
                    <SpecificObjectives data={specificObjectives} />
                </TabPanel>
                <TabPanel className='!p-0 !m-0' px={0}>
                    <LerningOutComes data={learningOutcomes} />
                </TabPanel>
                <TabPanel className='!p-0 !m-0' px={0}>
                    <Methodology data={moduleMethodology} />
                </TabPanel>
            </TabPanels>
        </Tabs>

    )

    const BreadcrumbMarkup = (
        <Breadcrumb size={"sm"} className="w-full block"
            separator={<FaAngleRight className=" font-extralight" color='gray.500' />}>
            <BreadcrumbItem className=" block  ">
                <Link to={'/app/my-learning'}>
                    <BreadcrumbLink display={'block'}>My Learning
                    </BreadcrumbLink>
                </Link>
            </BreadcrumbItem>
            <BreadcrumbItem className="block">
                <Link to={`/app/my-learning/${program_id}`}>
                    <BreadcrumbLink className=" truncate" display={'block'}>
                        {(isLoading || isFetching) ? <Skeleton height='20px' width="70px" /> : program_id}
                    </BreadcrumbLink>
                </Link>
            </BreadcrumbItem>
            <BreadcrumbItem className="block">
                <BreadcrumbLink className=" truncate" display={'block'}>
                    {(isLoading || isFetching) ?
                        <Skeleton height='20px' width="70px" /> : _.get(GeneralInfo, 'code', '')}
                </BreadcrumbLink>
            </BreadcrumbItem>
        </Breadcrumb>
    )

    return (

        <div className="flex flex-col items-center">
            <div className="container mt-4">
                {BreadcrumbMarkup}
                <div className="mt-8">
                    {
                        (isLoading && isFetching) ? <InnerLoading height={'70vh'} /> : tabMarkUp
                    }
                </div>
            </div>
        </div>
    )
}

export default MyLearningModuleViewPage