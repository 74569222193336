import { Text } from '@chakra-ui/react';
import { GoDotFill } from "react-icons/go";
import { TbCircleDot } from "react-icons/tb";
import React from "react";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";

interface IProps {
    title: string
    type: "upcoming" | "current" | "not_attended" | "attend",
    date: number,
    month: string
    time: any
}

const SessionDetailCard: React.FC<IProps> = ({ title, type, date, month, time }) => {
    // const dayOfMonth = Number(date);
    // const suffixes = ["st", "nd", "rd", "th"];
    // const suffix = dayOfMonth >= 4 && dayOfMonth <= 20 ? "th" : suffixes[dayOfMonth % 10 - 1];

    return (
        <div className="rounded-lg"
            style={{ backgroundColor: type === "current" ? "#4B89DC" : "#FFFFFF" }}>
            <div className="flex gap-8">
                <div className="bg-gradient-to-r from-gray-50 to-gray-100  rounded-lg shadow p-4">
                    <div className="flex flex-col gap-1">
                        <div className="w-24 flex justify-center">
                            <Text className="drop-shadow text-4xl font-bold">{date}</Text>
                        </div>
                        <div className="flex justify-center">
                            <Text className="font-bold drop-shadow">{month}</Text>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-2 justify-center">
                    <Text className="text-md text-white"
                        style={{ color: type === "current" ? "#FFFFFF" : "#000000" }}>{title}</Text>
                    <div className="flex space-y-1 flex-col">
                        {eventTypeIconMapping[type]}
                        <Text className="text-xs drop-shadow">{time}</Text>
                    </div>
                </div>
            </div>
        </div>
    )
}

const eventTypeIconMapping: any = {
    "upcoming": <div className="flex items-center gap-1">
        <Text className="text-xs text-gray-400">Upcoming</Text>
        <GoDotFill className="border-2 border-gray-400 rounded-full" color="#FFFFFF" size={14} />
    </div>,
    "current": <div className="flex items-center gap-1">
        <Text className="text-xs text-white">Upcoming</Text>
        <GoDotFill className="border-2 border-white rounded-full" color="#4B89DC" size={14} />
    </div>,
    "attended": <div className="flex items-center gap-1">
        <Text className="text-xs text-[#03A272]">Attended</Text>
        <IoIosCheckmarkCircleOutline className="border-2 border-white rounded-full" color="#03A272" size={22} />
    </div>,
    "not_attended": <div className="flex items-center gap-1">
        <Text className="text-xs text-[#A70202]">Not Attended</Text>
        <TbCircleDot className="rounded-full" color="#A70202" size={16} />
    </div>
}

export default SessionDetailCard