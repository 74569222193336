import _ from "lodash";
import { DASHBOARD } from "../../consts/methods.consts";
import { useGetQuery } from "../../services/api.service";
import SectionTitle from "./SectionTitle";
import { SimpleGrid } from "@chakra-ui/react";
import ProgramDetailCard from "../pages/my-learning/program-list-page/components/ProgramDetailCard";
import EmptyResult from "./empty-result/emptyResult";
import InnerLoading from "./inner-loading/InnterLoading";

const UpcommingPrograms = () => {

  const { data, isLoading, isFetching } = useGetQuery({
    method: DASHBOARD.GET_UPCOMMING_PROGRAMS,
    providesTags: []
  })

  const programs: Array<any> = _.get(data, 'message', [])

  const contentMarkUp =
    _.isArray(programs) && programs.length > 0 ? (
      <SimpleGrid overflow={'scroll'} maxH={'60vh'} columns={{ base: 1, md: 2, lg: 3 }} gap={{ base: '4', md: '10' }}>{
        programs.map((line: any, index: any) => (
          <ProgramDetailCard
            key={index}
            mode={line?.mode}
            program_id={line?.program_id}
            programName={line?.title}
            programCode={line?.code}
            noOfCredit={line?.credit}
            color={line?.color}
          />))
      }
      </SimpleGrid>
    ) : (
      <div className="w-full flex justify-center items-center mb-12"><EmptyResult showSymbol={false} title={"You dont have any upcomming programs"} /></div>
    )

  return (
    <>
      <SectionTitle title="Upcoming Programs" />
      {(isFetching || isLoading) ? <InnerLoading height={'10vh'} /> : contentMarkUp}
    </>
  );
};

export default UpcommingPrograms;