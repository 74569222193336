import { Badge, Box, Button, HStack, Heading, Stack, Text, ThemeTypings, ThemingProps, VStack, useBreakpointValue } from "@chakra-ui/react";
import { FiArrowLeft } from "react-icons/fi";
import { NavigateFunction, useNavigate } from "react-router";
import { boolean } from "yup";
import { showConfirmDialog } from "../../../store/reducers/confirm-modal";
import { useDispatch } from "react-redux";

type PageHeaderAction = {
    text: string,
    onClick: () => any,
    isDisabled?: boolean,
    confirmation?: boolean,
    buttonColorScheme?: ThemeTypings["colorSchemes"]
    buttonVariant?: ThemingProps<"Button">["variant"]
}

export type PageHeaderActions = Array<PageHeaderAction>;

export interface IPageHeaderProps {
    title: string,
    subtitle?: string,
    status?: string,
    statusColorSchemes?: ThemeTypings["colorSchemes"],
    actions?: PageHeaderActions
    actionRenderer?: JSX.Element,
    titleRenderer?: JSX.Element,
    subTitleRenderer?: JSX.Element,
    isLoading?: boolean
}

const PageHeader = ({ title, isLoading, subtitle, statusColorSchemes = "green", status, actions = [], actionRenderer, titleRenderer, subTitleRenderer }: IPageHeaderProps) => {
    const navigate: NavigateFunction = useNavigate();
    const dispatch = useDispatch()

    const onClickHandler = (confirm: Boolean | undefined, action: Function) => {
        if (confirm || confirm === undefined || confirm === null) {
            dispatch(
                showConfirmDialog({
                    title: "Confirmation Required!",
                    subtitle: "Are you sure you want to continue the action?",
                    onConfirm: action || (() => { })
                }))

        } else {
            action()
        }

    }

    const actionMarkup = actions.map((action: PageHeaderAction, index: number) =>
        <Button key={index} bg={'#2D3748'} _hover={{ backgroundColor: "black" }} size={"sm"} isDisabled={Boolean(action.isDisabled)}
            variant={action.buttonVariant || 'outline'} borderRadius={'7px'} lineHeight={'normal'} textColor={'white'} onClick={() => { onClickHandler(action?.confirmation, action.onClick) }}>
            {action.text}
        </Button>)

    return (
        <Box mb={4} w={"full"} className="bg-white p-5 rounded-2xl relative shadow" as="section" bg="bg-surface" >
            <Stack spacing="2" direction={{ base: 'column', md: 'row' }} justify="space-between">
                <HStack alignItems={"center"} spacing={3} width={{ base: '50%', md: '58%', xl: '80%' }}>

                    {!isLoading && (
                        <VStack alignItems={"flex-start"} width={'100%'} spacing="0" >
                            <HStack alignItems={"center"} width={'100%'} className="truncate" spacing={2}>
                                {/* Title Renderer */}
                                {titleRenderer && (
                                    <HStack alignItems={"center"} spacing={2}>
                                        {titleRenderer}
                                    </HStack>
                                )}
                                {/* Title */}
                                {(!titleRenderer && title) && (
                                    <Text className="truncate text-[1.3rem] font-bold" width={'100%'}>
                                        {title}
                                    </Text>
                                )}
                                {/* Status */}

                            </HStack>

                            <Stack
                                direction={{ base: 'column', sm: 'row' }}
                                spacing={{ base: '2', sm: '6' }}
                                color="muted">
                                {/* Subtitle Renderer */}
                                {subTitleRenderer && (
                                    <HStack alignItems={"center"} spacing={2}>
                                        {subTitleRenderer}
                                    </HStack>
                                )}
                                {/* Subtitle */}
                                <HStack>
                                    {(!subTitleRenderer && title) && <Text opacity={0.6} fontSize={"sm"}>{subtitle}</Text>}
                                </HStack>
                                <HStack>
                                    {status && (
                                        <Badge className="mt-[6px]" variant='solid' colorScheme={statusColorSchemes}>
                                            {status}
                                        </Badge>
                                    )}
                                </HStack>
                            </Stack>
                        </VStack>
                    )}

                    {isLoading && (
                        <VStack spacing={'3'} alignItems={"flex-start"}>
                            <div className="!h-[22px] border rounded-md w-28 bg-gray-200 animate-pulse"></div>
                            <div className="!h-[15px] border rounded-md w-20 bg-gray-200 animate-pulse"></div>
                        </VStack>
                    )}
                </HStack>
                {(!actionRenderer && actions.length > 0) && (
                    <Stack direction="row" spacing="3">
                        {actionMarkup}
                    </Stack>
                )}

                {actionRenderer}
            </Stack>

            {/* <div className=" absolute rounded-sm text-center bottom-[-25px] text-amber-600 px-2 text-xs py-0.5 bg-amber-200 left-0 right-0">Not Saved</div> */}
        </Box>
    )
}

export default PageHeader;