import { Avatar, useToast } from "@chakra-ui/react";
import _ from "lodash";
import { FaRegEye, FaRegTrashAlt } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { USER_TAG } from "../../../consts/tags.consts";
import { usePostMutation } from "../../../services/api.service";
import { ToastService } from "../../../services/toast.service";
import { customeJsonParser, getBase64 } from "../../../utils/utils";

interface ProfileAvatarProps {
    docname: string,
    placeholder?: string
    photoUrl?: string
}

const ProfileAvatar = ({ docname, placeholder, photoUrl }: ProfileAvatarProps) => {
    const toast = new ToastService(useToast())
    const dispatch: any = useDispatch()
    const [create] = usePostMutation();

    const isProfileImageExists = photoUrl ? true : false
    const profileImageUrl = photoUrl

    const onInputChange = (event: any) => {
        const file: File = event.target.files[0];

        if (!file) {
            toast.setTitle("Error").setDescription("No image selected. Please select a valid image file").setDuration(4000).showErrorToast()
            return;
        }

        if (file.size > (1 * 1024 * 1024)) {
            toast.setTitle("Error").setDescription("File size exceeds the maximum allowed size (5MB)").setDuration(4000).showErrorToast()
            return;
        }

        getBase64(file, async (result: any) => {
            if (file) {
                try {
                    let formData = new FormData();
                    formData.append('file', file);
                    formData.append('is_private', '1');
                    formData.append('folder', 'Home');
                    formData.append('doctype', 'Student');
                    // @ts-ignore
                    formData.append('docname', docname);
                    formData.append('fieldname', 'profie_picture');
                    await create({
                        method: 'xceed.api.data_import.upload_to_doc',
                        body: formData,
                        invalidatesTags: [USER_TAG.VIEW]
                    }).unwrap();

                    toast.setTitle("Succsess").setDescription("Profile image updated successfully").showSuccessToast();
                }
                catch (error: any) {
                    let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
                    if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
                }

            } else {
                toast.setTitle("Error").setDescription("Please select a valid file").setStatus('warning').show();
            }
        })
    }

    const onRemoveProfileImage = () => {
        create({
            method: 'xceed.student_portal.api.user.remove_profile_pic',
            body: {
                id: docname
            },
            invalidatesTags: [USER_TAG.VIEW]
        }).unwrap().then(() => {
            toast.setDescription("Profile image updated successfully").showSuccessToast()
        }).catch(() => {
            toast.setDescription("Unable to remove profile image").showErrorToast()
        })
    }

    const onViewProfileImage = () => {
        window.open(profileImageUrl, `_blank`)
    }

    return (
        <div className="flex h-full flex-col animation-form">
            <button type="button">
                <label htmlFor="file">
                    <Avatar loading={"lazy"} src={profileImageUrl} bgColor={"gray.200"} color={"blackAlpha.700"} className="cursor-pointer hover:bg-gray-300 transition-all !border-gray-400 !border-2 !text-3xl" w={"12rem"} h={"12rem"} name={placeholder || 'Full Name'} />
                </label>
            </button>
            <input disabled={isProfileImageExists} onChange={onInputChange} accept="image/*" type="file" id="file" style={{ display: "none" }} />
            {isProfileImageExists ? (
                <div className="flex justify-center gap-3 mt-2 items-center">
                    <div onClick={onRemoveProfileImage} className="flex items-center gap-1 cursor-pointer text-gray-500 hover:text-red-500 transition-all">
                        <FaRegTrashAlt />
                        <p className="text-xs">Remove</p>
                    </div>
                    <div onClick={onViewProfileImage} className="flex items-center gap-1 cursor-pointer text-gray-500 hover:text-blue-500 transition-all">
                        <FaRegEye />
                        <p className="text-xs">View</p>
                    </div>
                </div>
            ) : null}
        </div>
    )
}

export default ProfileAvatar;