import {SimpleGrid, Text} from "@chakra-ui/react";
import ModuleDetailCard from "./components/ModuleDetailCard";
import {useGetQuery} from "../../../services/api.service";
import {MODULE, USER} from "../../../consts/methods.consts";
import {useParams} from "react-router-dom";
import _ from "lodash";
import {useSelector} from "react-redux";

const userSelector = (state: any) => state?.common?.user
const ModuleListPage = () => {
    const currentUser = useSelector(userSelector)
    const {id, user} = useParams()

    const payload: any = user ? {method: USER.GET_USER_ENROLL_MODULES, body: {p_id: id, email: currentUser?.email}}
        : {method: MODULE.GET, body: {id}};

    const {data} = useGetQuery({
        ...payload,
        providesTags: []
    })

    const program_name: string = _.get(data, "message.program_name", "")
    const description: string = _.get(data, "message.description", "")
    const module_list: Array<any> = _.get(data, "message.module_list", [])

    return (
        <div className="flex flex-col items-center">
            <div className="container">
                <div className="mt-4">
                    <Text className="text-3xl font-bold">{program_name}</Text>
                    <p className="my-4 text-justify">{description}</p>
                    <Text className="text-lg font-bold">Modules</Text>
                    <SimpleGrid columns={{base: 1, md: 3}} gap={{base: '4', md: '4'}}>
                        {module_list.length === 0 ? <Text className="mt-4">Modules not
                            found</Text> : module_list.map((line: any, index: number) => (
                            <ModuleDetailCard key={index} moduleName={line?.title} duration={line?.duration}
                                              moduleCode={line?.code}
                                              noOfCredit={line?.credit} type={"Mandatory"}/>
                        ))}
                    </SimpleGrid>
                </div>
            </div>
        </div>
    )
}

export default ModuleListPage