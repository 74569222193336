import { useToast } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { ToastService } from "../../../../services/toast.service";
import { useEffect, useRef, useState } from "react";
import { useLogoutMutation } from "../../../../services/api.service";
import { NavigateFunction, useNavigate } from "react-router-dom";
import _ from "lodash";

const userSelector = (state: any) => state?.common?.user
const Menu = () => {
    const ref: any = useRef(null)
    const user = useSelector(userSelector)
    const navigate: NavigateFunction = useNavigate();
    const toast = new ToastService(useToast())
    const [logout] = useLogoutMutation();
    const [isVisibleMenu, setIsVisibleMenu] = useState(false)

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const src = _.get(user, 'profie_picture', false) ? _.get(user, 'profie_picture') : `https://ui-avatars.com/api/?name=${_.get(user, 'full_name')?_.get(user, 'full_name'):_.get(user,'email')}&background=${'AFECEF'}`


    const logoutHandler = async () => {
        try {
            await logout({}).unwrap();

            navigate("/", { replace: true })
            window.location.reload()

        } catch (error) {
            console.error(error);
            toast.setTitle("Error").setDescription("Unable to logout. Something went wrong").showErrorToast();
        }
    }
    const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsVisibleMenu(false);
        }
    };

    const menuMarkup = isVisibleMenu && (
        <div ref={ref}
            className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-100">

            <div className="py-1" role="none">
                <button className="text-gray-700 block w-full px-4 py-2 text-left text-sm hover:bg-gray-100"
                    type="submit" role="menuitem" id="menu-item-3">
                    {user?.email}
                </button>
                <button onClick={logoutHandler}
                    className="text-gray-700 block w-full px-4 py-2 text-left text-sm hover:bg-gray-100"
                    type="submit" role="menuitem" id="menu-item-3">
                    Sign out
                </button>
            </div>
        </div>
    )

    return (
        <div tabIndex={0}
            className="relative inline-block text-left py-1 ps-1 pe-1 rounded-md hover:bg-gray-100 cursor-pointer">
            <div onClick={() => setIsVisibleMenu(prevState => !prevState)}>
                <div className="w-full justify-center items-center gap-x-1.5">
                    <img className="inline-block h-8 w-8 rounded-md ring-2 ring-white"
                        src={src}
                        alt="" />
                </div>
            </div>
            {menuMarkup}
        </div>
    )
}


export { Menu }