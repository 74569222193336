export enum STUDENT_TAGS {
    LIST = "STUDENT_LIST",
    VIEW = "STUDENT_VIEW"
}

export enum MODULE_TAGS {
    LIST = "MODULE_LIST",
    VIEW = "MODULE_VIEW"
}

export enum PROGRAMMED_MODULE_TAGS {
    LIST = "PROGRAMMED_MODULE_LIST",
    VIEW = "PROGRAMMED_MODULE_VIEW"
}

export enum MODULE_BUCKET_TAGS {
    LIST = "MODULE_BUCKET_LIST",
    VIEW = "MODULE_BUCKET_VIEW"
}

export enum MODULE_ENROLLMENT_TAGS {
    LIST = "MODULE_ENROLLMENT_LIST",
    VIEW = "MODULE_ENROLLMENT_VIEW"
}

export enum ATTENDANCE_TAGS {
    LIST = "ATTENDANCE_LIST",
    VIEW = "MATTENDANCE_LIST_VIEW"
}

export enum CLASS_ROOM_TAGS {
    LIST = "CLASS_ROOM_LIST",
    VIEW = "CLASS_ROOM_VIEW"
}


export enum MODULE_BUCKET_TYPE_TAGS {
    LIST = "MODULE_BUCKET_TYPE_LIST",
    VIEW = "MODULE_BUCKET_TYPE_VIEW"
}


export enum ORGANIZATION_TAGS {
    LIST = "ORGANIZATION_LIST",
    VIEW = "ORGANIZATION_VIEW"
}

export enum EMPLOYEE_TAGS {
    LIST = "EMPLOYEE_LIST",
    VIEW = "EMPLOYEE_VIEW"
}

export enum EXAM_ENROLLMENT_TAGS {
    LIST = "EXAM_ENROLLMENT_LIST",
    VIEW = "EXAM_ENROLLMENT_VIEW"
}


export enum RESOURCE_PERSON_TAGS {
    LIST = "ESOURCE_PERSON_LIST",
    VIEW = "ESOURCE_PERSON_VIEW"
}



export enum PROGRAM_TAGS {
    LIST = "PROGRAM_LIST",
    VIEW = "PROGRAM_VIEW"
}

export enum REPORT_TAGS {
    LIST = "REPORT",
}

export enum PROGRAM_CATEGORY_TAGS {
    LIST = "PROGRAM_CATEGORY_LIST",
    VIEW = "PROGRAM_CATEGORY_VIEW"
}

export enum PROGRAM_TYPE_TAGS {
    LIST = "PROGRAM_TYPE_LIST",
    VIEW = "PROGRAM_TYPE_VIEW",
    INIT = "PROGRAM_TYPE_INIT"
}

export enum PROGRAM_SUBTYPE_TAGS {
    LIST = "PROGRAM_SUBTYPE_LIST",
    VIEW = "PROGRAM_SUBTYPE_VIEW"
}
export enum SESSION_TAGS {
    LIST = "SESSION_LIST",
    VIEW = "SESSION_VIEW"
}

export enum DATA_IMPORT_TAG {
    LIST = "DATA_IMPORT_LIST",
    VIEW = "DATA_IMPORT_VIEW"
}

export enum STUDENT_TAG {
    LIST = "STUDENT_LIST",
    VIEW = "STUDENT_VIEW"
}

export enum CALENDAR_TAGS {
    EVENTS = "CALENDAR_EVENTS",
}

export enum FILTER_TAGS {
    STUDENT_SELECTOR = 'STUDENT_SELECTOR'
}

export enum DISCLAIMER_TAGS {
    LIST = "DISCLAIMER_LIST",
    VIEW = "DISCLAIMER_VIEW"
}

export enum EXAM_TAGS {
    LIST = "EXAM_LIST",
    VIEW = "EXAM_VIEW",
    INIT = "EXAM_INIT"
}

export enum DROPDOWN_TAGS {
    LIST = "DROPDOWN"
}

export enum SERVICE_TAGS {
    LIST = "SERVICE_list"
}

export enum SUBJECT_TAGS {
    LIST = "SUBJECT_LIST",
    VIEW = "SUBJECT_VIEW"
}

export enum USER_TAG {
    VIEW = "USER_VIEW"
}

export enum MY_LEARN_TAGS {
    LIST = "ENROLL_MODULE_LIST",
    MODULE_VIEW = "MODULE_VIEW"
}

export enum NOTIFICATION_TAGS {
    LIST = "NOTIFICATION_LIST",
    MODULE_VIEW = "MODULE_VIEW"
}