import "./styles/global.css";

import store from "./store/store";
import { Provider } from "react-redux";
import RouterConfigs from "./routes/route-configs";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider, Tr, defineStyleConfig, extendTheme, space } from "@chakra-ui/react";
import ConfirmModal from "./components/common/confirmation-modal/ConfirmationModal";
import { BiBorderRadius } from "react-icons/bi";

const breakpoints = {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    '2xl': '1536px',
}

const Tabs = defineStyleConfig({
    defaultProps: {
        colorScheme: 'teal'
    }
})

const theme = extendTheme({
    breakpoints,
    styles: {
        global: {
            '.chakra-checkbox__control': {
                width: '100px',
                height: '50px',
            },
            '.css-v6hixf': {
                width: '100px',
                height: '50px',
            }
        },
    },
    colors: {
        black: {
            500: '#000000',
        },
    }
    ,
    fonts: {
        body: 'Inter, sans-serif',
    },
    components: {
        Tabs,
        Checkbox: {
            baseStyle: {
                fontWeight: 'bold',
                gap: '100px',
                rounded: 'lg',
                width: '500px',
                label: {
                    width: '100%',
                },
                control: {
                    width: '24px',
                    height: '24px',
                    borderRadius: '5px',
                },
            },
            sizes: {
                sm: {
                    control: {
                        width: '20px',
                        height: '20px',
                        borderRadius: '5px',
                    },

                }
            }
        }
    }
});

export const App = () => {
    return (
        <Provider store={store}>
            <ChakraProvider theme={theme}>
                <BrowserRouter>
                    <RouterConfigs />
                </BrowserRouter>
                <ConfirmModal />
            </ChakraProvider>
        </Provider>
    )
}
