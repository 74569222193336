import SessionDetailCard from "./SessionDetailCard";
import { Text } from '@chakra-ui/react';
import { useGetQuery } from "../../../../../../services/api.service";
import { ALLPROGRAM } from "../../../../../../consts/methods.consts";
import { useParams } from "react-router-dom";
import _ from "lodash";
import EmptyResult from "../../../../../common/empty-result/emptyResult";

const Session = () => {
    const { id } = useParams()

    const { data, isFetching, isLoading } = useGetQuery(
        {
            method: ALLPROGRAM.GET_SESSIONS,
            body: {
                p_module: id
            },
            providesTags: []
        }
    )

    const currentSessions: Array<any> = _.get(data, "message.current_sessions", [])
    const previousSessions: Array<any> = _.get(data, "message.previous_sessions", [])
    const upcomingSessions: Array<any> = _.get(data, "message.upcoming_sessions", [])

    return (
        <>
            <div className="flex flex-col gap-4 rounded-2xl p-4 bg-white">
                <Text className="text-lg text-black">Current</Text>
                {currentSessions.length > 0 ? currentSessions.map((line: any, index: number) => {
                    const { dateOnly, monthOnly, timeOnly } = extractDateTime(line?.start)
                    const { timeOnly: endTime } = extractDateTime(line?.end)

                    return <SessionDetailCard key={index} title={line?.title} date={dateOnly}
                        type={line?.attendance} month={monthMapping[monthOnly]}
                        time={`${timeOnly} - ${endTime}`} />
                }) : <EmptyResult title={"No Current Sessions"} />}
            </div>

            <div className="flex flex-col gap-4 mt-6 rounded-2xl p-4 bg-white">
                <Text className="text-lg text-black">Upcoming Sessions</Text>
                {upcomingSessions.length > 0 ? upcomingSessions.map((line: any, index: number) => {
                    const { dateOnly, monthOnly, timeOnly } = extractDateTime(line?.start)
                    const { timeOnly: endTime } = extractDateTime(line?.end)

                    return (
                        <SessionDetailCard
                            key={index} title={line?.title} date={dateOnly}
                            type={line?.attendance} month={monthMapping[monthOnly]}
                            time={`${timeOnly} - ${endTime}`}
                        />
                    )
                }) : <EmptyResult title={"No Upcoming Sessions"} />}
            </div>

            <div className="flex flex-col gap-4 mt-6 rounded-2xl p-4 bg-white">
                <Text className="text-lg text-black">Previous Sessions</Text>
                {previousSessions.length > 0 ? previousSessions.map((line: any, index: number) => {
                    const { dateOnly, monthOnly, timeOnly } = extractDateTime(line?.start)
                    const { timeOnly: endTime } = extractDateTime(line?.end)

                    return <SessionDetailCard key={index} title={line?.title} date={dateOnly}
                        type={line?.attendance} month={monthMapping[monthOnly]}
                        time={`${timeOnly} - ${endTime}`} />
                }) : <EmptyResult title={"No Previous Sessions"} />}
            </div>

        </>
    )
}

const monthMapping: any = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December"
}

const extractDateTime = (value: string) => {
    const date = new Date(value)
    const dateOnly = date.getDate()
    const monthOnly = date.getMonth() + 1
    const timeOnly = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });

    return { dateOnly, monthOnly, timeOnly }
}

export default Session