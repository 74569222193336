import _ from "lodash";
import SectionTitle from "./SectionTitle";
import EmptyResult from "./empty-result/emptyResult";
import { useEffect, useState } from "react";

const LatestNews = () => {

    const [response, setResponse] = useState([] as any)
    const BASE_URL = 'https://slida-cms.dlad.io/api/';

    const commonParams = {
        populate: [
            '*'
        ]
    }

    async function fetchData() {
        const [pageRes, newsRes] = await Promise.all([
            fetch(`${BASE_URL}news-page`, { cache: 'no-store' }),
            fetch(`${BASE_URL}website-news`, { cache: 'no-store' }),
        ]);

        return await Promise.all([pageRes.json(), newsRes.json()]);
    }

    useEffect(() => {
        fetchData().then((result) => setResponse(result)).catch((error) => console.log(error))
    }, [])

    const [pageData, newsData] = response;
    const newsList: any = _.get(newsData, ['data'], [])

    return (
        <>
            <SectionTitle title="Latest News" />
            {_.isArray(newsList) ?
                (
                    <div className="flex flex-col space-y-4">
                        {newsData?.data.map((line: any) => (
                            <NewsLine date={line.attributes.Date} title={line.attributes.Title} description={line.attributes.Text}/>
                        ))}
                    </div>
                )
                :
                (
                    <div className="w-full flex justify-center items-center mb-12">
                        <EmptyResult showSymbol={false} title={"No any news found"} />
                    </div >
                )
            }
        </>
    );
};


const NewsLine = ({ title, description, date }: any) => {

    return (
        <div className="p-4 bg-white rounded-lg flex flex-col space-y-2">
            <p>{date}</p>
            <h4 className="text-xl font-bold text-black sm:text-xl lg:text-xl lg:leading-tight xl:text-xl xl:leading-tight">
                {title}
            </h4>

            <p>{description}</p>
        </div>
    )
}


export default LatestNews;