import { SimpleGrid, Text } from "@chakra-ui/react";
import { useGetQuery } from "../../../../services/api.service";
import { MY_LEARN } from "../../../../consts/methods.consts";
import _, { filter } from "lodash";
import ProgramDetailCard from "./components/ProgramDetailCard";
import React, { JSX, useRef, useState } from "react"
import InnerLoading from "../../../common/inner-loading/InnterLoading";
import EmptyResult from "../../../common/empty-result/emptyResult";
import FilterPanel from "../../../common/FilterPanle/FilterPanel";
import FilterSelectedTag from "../../../common/FilterSelectedTag/FilterSelectedTag";

const ProgramListPage = () => {
    const [filterPanleData, setFilterPanelData] = useState({})
    const filterRef: any = useRef(() => { })

    const template = '{"category":"${_.get(filter,"category[0].value","")}" }'

    const { data: enrollProgramsData, isLoading, isFetching } = useGetQuery({
        method: MY_LEARN.GET_ENROLL_PROGRAMS,
        body: {
            ...filterPanleData
        },
        providesTags: []
    })

    const enrollPrograms: Array<any> = _.get(enrollProgramsData, 'message', [])

    const contentMarkup: JSX.Element[] = enrollPrograms.map((line: any, index: number) => (
        <ProgramDetailCard program_id={line?.program_id} key={index} status={line?.status} programName={line?.title}
            programCode={line?.code} color={line?.color} mode={line?.mode}
            noOfCredit={line?.credit} />
    ))

    const onFilter = (result: any) => {
        setFilterPanelData(result)
    }

    const onCloseTag = (key: any, index: any) => {
        filterRef.current.removeFilter(key, index)
    }

    return (

        <div className="flex flex-col items-center">
            <div className="container">
                <div className="mt-4">
                    <div className="flex items-center justify-between p-6 bg-white mb-8 rounded-2xl shadow-sm">
                        <Text className="text-[1.3rem] font-bold">My Learning</Text>
                        <div className="flex gap-4 justify-between">
                            <FilterSelectedTag filter={filterPanleData} onCloseTag={onCloseTag} />
                            <FilterPanel ref={filterRef} body={template} onFilter={onFilter} api={MY_LEARN.GET_FILTER_PANEL_DATA} />
                        </div>

                    </div>
                    {(isLoading || isFetching) ? <InnerLoading height={'75vh'} /> : enrollPrograms.length === 0 ?
                        <EmptyResult title="No Programs You Enroll" /> :
                        <SimpleGrid
                            className="mt-8"
                            gridGap="8"
                            columns={{ base: 1, md: 2, lg: 3 }}
                            gap={{ base: '4', md: '7' }}> {contentMarkup}
                        </SimpleGrid>}
                </div>
            </div>
        </div>
    )
}

export default ProgramListPage
