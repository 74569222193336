import { useNavigate } from "react-router-dom"
import camslogo from "../../../assets/images/Cams-011.jpg"
import { Image } from '@chakra-ui/react'


export const XceedLogo = () => {
    const navigation = useNavigate();

    const onClick = () => {
        navigation("/app")
    }

    return (
        <>
            <Image
                onClick={onClick}
                className={" hover:cursor-pointer"}
                width='100px'
                height={'30px'}
                objectFit='contain'
                src={camslogo}
                alt={camslogo}
            />
        </>
    )
}