import { Text } from "@chakra-ui/react";
import React from "react";
import { ImLocation2 } from "react-icons/im";

interface IProps {
    percentage: number,
    total_program_credit: number,
    total_completed_module_credit: number,
    percentage_of_enrolled_module_credit: number,
    total_enroll_module_credit: number
}

const OverallProgress: React.FC<IProps> = ({
    percentage,
    total_program_credit,
    total_completed_module_credit,
    percentage_of_enrolled_module_credit,
    total_enroll_module_credit
}) => {

    return (
        <div className="flex flex-col gap-1 p-5 shadow bg-white rounded-2xl">
            <Text className="text-xs text-gray-500 mb-1">Overall Progress</Text>
            <div
                className="relative w-full  bg-gray-200 h-2 rounded-full mt-7"
                style={{ width: `100%` }}>
                <div className="absolute bg-[#4BDCB0] h-2 rounded-full z-40"
                    style={{ width: `${total_completed_module_credit > 100 ? 100 : total_completed_module_credit}%` }}>
                    <Text style={{ display: total_completed_module_credit < 96 ? "none" : "block" }}
                        className="absolute z-50 end-4 -top-8 text-xs">{total_completed_module_credit} Credit</Text>
                    <ImLocation2 size={24} color="#4BDCB0" className="absolute z-50 -end-3 -top-8" />
                    <Text style={{ display: total_completed_module_credit > 96 ? "none" : "block" }}
                        className="absolute z-50 -end-[70px] -top-8 text-xs">{total_completed_module_credit} Credit</Text>
                    <div className="absolute z-50 -end-2 -top-[4.5px] bg-[#4BDCB0] rounded-full h-4 w-4"></div>
                </div>
                <div className="absolute bg-[#F0D43F] h-2 rounded-full z-20"
                    style={{ width: `${percentage_of_enrolled_module_credit > 100 ? 100 : percentage_of_enrolled_module_credit}%` }}>
                    <Text style={{ display: percentage_of_enrolled_module_credit < 96 ? "none" : "block" }}
                        className="absolute z-50 end-4 -top-8 text-xs">{total_enroll_module_credit} Credit</Text>
                    <ImLocation2 size={24} color="#F0D43F" className="absolute z-50 -end-3 -top-8" />
                    <Text style={{ display: percentage_of_enrolled_module_credit > 96 ? "none" : "block" }}
                        className="absolute z-50 -end-[70px] -top-8 text-xs">{total_enroll_module_credit} Credit</Text>
                    <div className="absolute z-50 -end-2 -top-[4.5px] bg-[#F0D43F] rounded-full h-4 w-4"></div>
                </div>
                <div className="absolute z-50 -end-2 -top-[4.5px] bg-gray-200 rounded-full h-4 w-4"></div>
            </div>
            <div className="flex justify-end items-end mt-2 -mb-4">
                <Text className="text-xs">{total_program_credit} Credit</Text>
            </div>
            <div className="flex gap-4 mt-4">
                <div className="flex gap-2 items-center">
                    <ImLocation2 size={24} color="#4BDCB0" /><Text>Completed module Credit</Text>
                </div>
                <div className="flex gap-2 items-center">
                    <ImLocation2 size={24} color="#F0D43F" /><Text>Enroll module Credit</Text>
                </div>
            </div>
        </div>
    )
}

export default OverallProgress