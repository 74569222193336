import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Text, useToast } from "@chakra-ui/react";
import { FaAngleRight } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import PageHeader from "../../../common/page-header-v2/PageHeader";
import { LuPencilLine } from "react-icons/lu";
import { FormTextArea } from "../../../common/form-controllers/FormTextArea";
import { useFormik } from "formik";
import { PageHeaderActions } from "../../../common/page-header/PageHeader";
import { useObjChange } from "../../../hooks";
import { useGetQuery, usePostMutation } from "../../../../services/api.service";
import { ToastService } from "../../../../services/toast.service";
import { customeJsonParser } from "../../../../utils/utils";
import _ from "lodash";
import { MY_LEARN } from "../../../../consts/methods.consts";

const FeedbackPage = () => {
    const { id } = useParams()
    const toast = new ToastService(useToast());
    const [create] = usePostMutation()
    const navigate = useNavigate();

    const { data, isLoading, isFetching } = useGetQuery({
        method: MY_LEARN.GET_PROGRAM_DETAILS,
        body: {
            program: id
        },
        providesTags: []
    })
    const title = _.get(data, "message.title", "")
    const formik = useFormik({
        initialValues: {},
        onSubmit: async (values: any) => {
            try {
                await create({
                    method: MY_LEARN.GIVE_FEEDBACK,
                    body: {
                        program: id,
                        ...values
                    },
                    invalidatesTags: []
                }).unwrap()

                toast.setTitle("Success").setDescription("Feedback have been saved.").showSuccessToast();
                navigate(-1)
            } catch (error: any) {
                let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
                if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
            }
        }
    })

    const { isChanged, setInitialObj } = useObjChange(formik.values)

    useEffect(() => {
        if (isChanged) {
            setInitialObj({ feedback: "" })
        }
    }, [formik.values]);

    const actions: PageHeaderActions = [
        {
            text: "Save",
            isDisabled: !isChanged,
            onClick: formik.submitForm,
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        }
    ]

    const BreadcrumbMarkup = (
        <Breadcrumb size={"sm"} className="w-full block"
            separator={<FaAngleRight className="font-extralight" color='gray.500' />}>
            <BreadcrumbItem className=" block  ">
                <Link to={'/app/my-learning'}>
                    <BreadcrumbLink display={'block'}>My Learning
                    </BreadcrumbLink>
                </Link>
            </BreadcrumbItem>
            <BreadcrumbItem className="block">
                <Link to={`/app/my-learning/${id}`}>
                    <BreadcrumbLink className=" truncate" display={'block'}>
                        {id}
                    </BreadcrumbLink>
                </Link>
            </BreadcrumbItem>
            <BreadcrumbItem className="block">
                <Link to={'#'}>
                    <BreadcrumbLink className=" truncate" display={'block'}>
                        Feedback
                    </BreadcrumbLink>
                </Link>
            </BreadcrumbItem>
        </Breadcrumb>
    )

    return (
        <div className="flex flex-col items-center bg-[#F7F8FC]">
            <div className="container mt-4">
                {BreadcrumbMarkup}
                <div className="mt-8">
                    <PageHeader title={title} subtitle={""} actions={actions} />
                    <div className="flex items-center gap-3">
                        <Text className="text-xl">Feedback</Text>
                        <LuPencilLine />
                    </div>
                    <FormTextArea className="mt-4" maxLength={2000} formik={formik} name="feedback" />
                </div>
            </div>
        </div>
    )
}

export default FeedbackPage