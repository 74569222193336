
const HomeFooterBanner = () => {
    return (
        <section className="pb-20 sm:pb-28 lg:pb-28 mt-32">
            <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-screen-xl">
                <div className="flex flex-col items-center justify-center">
                    <h2 className="drop-shadow text-5xl font-extrabold leading-snug sm:text-6xl xl:text-6xl sm:leading-tight xl:leading-tighter max-w-2xl text-center text-black">SLIDA Learning Platforms</h2>
                    <p className="mx-auto mt-4 max-w-3xl text-center text-xl leading-relaxed text-gray-600">
                        At SLIDA, we leverage advanced software applications to enhance the learning experience for our students and streamline administrative processes. These state-of-the-art technologies are designed to ensure seamless access to
                        educational resources, foster collaborative learning, and maintain academic excellence.
                    </p>
                </div>
                <div className="mt-12 grid gap-8 sm:grid-cols-2 sm:gap-6 md:mt-14 md:gap-8 lg:mt-16 lg:gap-6 xl:mt-20 xl:grid-cols-4 2xl:mt-24 2xl:gap-12">
                    <a className="bg-white rounded-3xl px-8 py-12 transition flex justify-center items-center flex-col duration-300 ease-in-out sm:px-6 md:px-8 lg:px-5 2xl:px-8 group hover:-translate-y-1 hover:shadow-sm cursor-pointer shadow" href="#" >
                        <p className="mt-3 text-xl font-bold text-black capitalize text-center">CAMS - Centralised Academic Management System</p>
                    </a>
                    <a className="bg-white bg-purple-25 rounded-3xl px-8 py-12 transition flex justify-center items-center flex-col duration-300 ease-in-out sm:px-6 md:px-8 lg:px-5 2xl:px-8 group hover:-translate-y-1 hover:shadow-sm cursor-pointer shadow" href="#">
                        <p className="mt-3 text-xl font-bold text-black capitalize text-center">XCEED - Centralised Examination Admission System</p>
                    </a>
                    <a className="bg-white rounded-3xl px-8 py-12 transition flex justify-center items-center flex-col duration-300 ease-in-out sm:px-6 md:px-8 lg:px-5 2xl:px-8 group hover:-translate-y-1 hover:shadow-sm cursor-pointer shadow" href="#">
                        <p className="mt-3 text-xl font-bold text-black capitalize text-center">GeLP - Government eLearning Platform</p>
                    </a>
                    <a className="bg-white rounded-3xl px-8 py-12 transition flex justify-center items-center flex-col duration-300 ease-in-out sm:px-6 md:px-8 lg:px-5 2xl:px-8 group hover:-translate-y-1 hover:shadow-sm cursor-pointer shadow" href="#">
                        <p className="mt-3 text-xl font-bold text-black capitalize text-center">LMS - SLIDA Learning Management System</p>
                    </a>
                </div>
            </div>
        </section>
    );
};

export default HomeFooterBanner;