import { SimpleGrid, Flex, HStack, Stack, useBreakpointValue, Box, Text, Image } from "@chakra-ui/react"
import loginBackground from "../../../assets/images/login-background.png"
import { XceedLogo } from "../../common/logo/Logo"
import ChangePasswordForm from "./components/ChangePasswordForm"

const RegistrationCompletePage = () => {
    const imageMarkup = (
        <Image src={loginBackground} className="object-cover" height={{base: '45vw', md: '25vw'}}
               paddingX={{base: '40px', md: '0px'}} alt="login page"/>
    )
    return (<SimpleGrid className="bg-[#F8F9FF] h-screen" columns={{base: 1, md: 2}}>
    <Box className="hidden md:block">
        <Flex direction="column" px={{base: '4', md: '8'}} height="full" color="on-accent">
            <Flex className=" w-full md:w-auto" align="center" alignSelf="flex-start" h="24" paddingLeft="4">
                <HStack spacing={0} className="w-[110px] h-[24px] lg:w-full" alignItems={"start"}
                        color={"white"}>
                    <XceedLogo/>
                </HStack>
                {/* <div className="flex w-full items-center justify-end sm:hidden">
                    <Button size={'xs'} onClick={() => navigator('/application')}>
                        Examination portal
                    </Button>
                </div> */}
            </Flex>
            <Flex flex="1" align="center" className="justify-center" color={'white'} paddingLeft="4">
                <Stack spacing="8">
                    <Stack spacing="6" display={{base: 'none', md: 'block'}}>
                        <Text className="text-gray-600 font-bold text-3xl  leading-normal "
                              size={useBreakpointValue({md: 'lg', xl: 'xl'})}>
                            Sri Lanka Institute of Development Administration
                        </Text>
                        <Text className="text-[#20232066] mt-2" maxW="2xl" fontWeight="medium">
                            Premier public sector training organization in Sri Lanka, for the development of
                            knowledge and improvement of skills in Public Administration and Management.
                        </Text>

                    </Stack>
                    {imageMarkup}
                </Stack>
            </Flex>
            <Flex align="center" display={{base: 'none', md: 'block'}} paddingLeft="4" marginBottom="4">
                <Text
                    className={"font-[Poppins] opacity-[0.8] font-normal text-xs text-opacity-60 text-[#202320] "}>
                    © 2023 DLAD Software Solution. All rights reserved.
                </Text>
            </Flex>
        </Flex>
    </Box>
    <ChangePasswordForm
        width="100%"

    />

</SimpleGrid>)

}

export default RegistrationCompletePage