import { Button, Text } from "@chakra-ui/react";
import { SlBadge } from "react-icons/sl";
import React from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { BsCircle } from "react-icons/bs";

interface IProps {
    code: string,
    title: string,
    module_id: string,
    type: string,
    credit: string,
    duration_days: string
}

const ModuleDetailsCard: React.FC<IProps> = ({ code, title, module_id, type, credit, duration_days }) => {
    const navigate: NavigateFunction = useNavigate();
    const CardBgColour: string = type === 'Mandatory' ? '#FCF7F7' : '#F7FCF9'
    const moduleTypeColour: string = type === 'Mandatory' ? '#DC4B4B33' : '#D9DC4B33'
    const moduleTypeTextColour: string = type === 'Mandatory' ? '#770000' : '#53410099'

    const onclickCardHandler = () => {
        navigate(`/app/all-programs/module/${module_id}`)
    }

    return (
        <div onClick={onclickCardHandler}
            className="flex flex-col bg-white max-h-[300px] box-border p-4 space-y-5 rounded-lg hover:-translate-y-1 hover:cursor-pointer shadow transition-all">

            <div className="space-y-2">
                <div className="flex justify-between items-center">
                    <Text className="text-md font-[600] text-gray-700">{code}</Text>
                    <Text
                        className="text-xs text-gray-700">Duration {duration_days} Days</Text>
                </div>

                <div className="flex gap-4">
                    <div>
                        <span style={{ backgroundColor: moduleTypeColour, color: moduleTypeTextColour }}
                            className="text-xs font-medium px-2.5 py-1 rounded-md">{type}</span>
                    </div>
                </div>
            </div>
            <div className="h-[50px] overflow-hidden">
                <Text className="line-clamp-3 text-ellipsis overflow-hidden text-md text-gray-600">{title}</Text>
            </div>
            <div className="flex justify-between items-center ">
                <div className="flex justify-between items-end gap-2">
                    <div className="bg-[#dbf8ef] rounded-full p-1">
                        {/* <SlBadge size={15} /> */}
                        <BsCircle className="text-[#78e5c3]" size={15} />
                    </div>
                    <Text className="self-center text-sm text-gray-700">No of Credits {credit}</Text>
                </div>
                {/* <Button _hover={{ backgroundColor: "black" }} fontSize={'xs'} size="sm" padding={'4'}
                    textColor={'white'} borderRadius={'7px'} lineHeight={'normal'} onClick={onclickCardHandler} className="sm:self-end p-3" bg={'#2D3748'}>View</Button> */}
            </div>
        </div>
    )
}

const statusColorMapping: any = {
    'Pass': "bg-green-500",
    'Fail': 'bg-red-600'
}


export default ModuleDetailsCard