import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Button,
    ButtonProps,
    Flex,
    FlexProps,
    HStack,
    Icon,
    Link,
    LinkProps,
    Stack,
    Text,
    TextProps,
    useColorModeValue as mode
} from '@chakra-ui/react'
import _ from 'lodash'
import {
    BsBook,
    BsBriefcaseFill,
    BsBuilding,
    BsFillClipboard2CheckFill,
    BsFillGrid1X2Fill,
    BsFolder,
    BsTagFill
} from "react-icons/bs"
import { FaBitbucket, FaBookOpen, FaChalkboardTeacher, FaRegCalendarAlt } from "react-icons/fa"
import { FiArrowRight, FiArrowUpRight, FiGrid, FiHome } from 'react-icons/fi'
import { HiMiniClipboardDocumentCheck } from 'react-icons/hi2'
import { ImBooks, ImManWoman } from 'react-icons/im'
import { IconType } from 'react-icons/lib'
import { MdEventAvailable, MdSubject } from 'react-icons/md'
import { Link as ReactRouterLink, NavigateFunction, useNavigate } from 'react-router-dom'
import { usePathMetaProvider } from '../../hooks'
import { XceedLogo } from '../logo/Logo'
import { useSelector } from "react-redux";
import { CgProfile } from "react-icons/cg";

interface NavbarProps extends FlexProps {
    onClose?: () => void
    closeSideBar: () => void
}

type ILinks = {
    icon: IconType,
    label: string,
    onClick?: () => any,
    path?: string,
    disabled?: boolean,
    children?: Array<ILinks>,
    to?: string
}
type INavElement = { heading: string, links: Array<ILinks> }

const userSelector = (state: any) => state?.common?.user

export const SideBar = (props: NavbarProps) => {
    const navigate: NavigateFunction = useNavigate();
    const activePath: string = usePathMetaProvider();
    const user = useSelector(userSelector)
    const closeSideBar = props.closeSideBar

    const navConfig: Array<any> = [
        {
            heading: "Program Management",
            links: [
                {
                    icon: ImBooks,
                    label: "Program",
                    path: "program",
                    to: "/app/program",
                    // onClick: () => {
                    //     navigate("/app/program")
                    // },
                }
            ]
        },
        {
            heading: "Module Management",
            links: [
                {
                    icon: MdSubject,
                    label: "Module",
                    path: "modules",
                    to: "/app/modules",
                    disabled: false,
                    // onClick: () => {
                    //     navigate("/app/modules")
                    // }
                }
            ]
        },
        {
            heading: "",
            links: [
                {
                    icon: FiArrowRight,
                    label: "Exam Management",
                    children: [
                        {
                            icon: FiArrowRight,
                            label: "Subject",
                            path: "subject",
                            disabled: false,
                            to: "/app/subject"
                        }
                    ]
                }
            ]
        },
        {
            heading: "Organization Management",
            links: [
                {
                    icon: BsBuilding,
                    label: "Organization",
                    path: "organization",
                    to: "/app/organization",
                    disabled: false,
                    // onClick: () => {
                    //     navigate("/app/organization")
                    // }
                },
            ]
        },
        {
            heading: "Reports & Analysis",
            links: [
                {
                    icon: ImBooks,
                    label: 'Reports',
                    path: "report-page",
                    to: "/app/report-page",
                }
            ]

        }
    ]

    const navMarkup = navConfig.map((line: INavElement, index: number) => {
        return (
            <Stack spacing="1" key={index}>
                <NavHeading>{line.heading}</NavHeading>
                <Stack spacing="1">
                    {line.links.map((link: ILinks, index: number) => {
                        if (link?.children && !_.isEmpty(link.children)) {
                            return (
                                <ParentNavLink key={index} {...link} activePath={activePath} />
                            )
                        } else {
                            return (
                                <NavLink
                                    key={index}
                                    fontWeight={"semibold"}
                                    pointerEvents={link.disabled ? 'none' : 'auto'}
                                    className={link.disabled ? 'opacity-50' : ''}
                                    aria-current={activePath === link.path ? 'page' : undefined}
                                    onClick={link.onClick}
                                    to={link?.to}
                                    icon={link.icon}>
                                    {link.label}
                                </NavLink>
                            )
                        }
                    })}
                </Stack>
            </Stack>
        )
    });

    return (
        <Flex as="nav" height="full" direction="column" justify="space-between" {...props}>
            <Stack spacing="3">
                <HStack spacing={0} ml={2} display={'flex'} p={3} justifyContent={'start'} alignItems={"center"}>
                    <XceedLogo />
                </HStack>
                <Stack px="3" spacing="2">
                    <Stack spacing="1">
                        <NavLink to='/app' closeSideBar={closeSideBar}
                            aria-current={activePath === 'home' ? 'page' : undefined}
                            icon={'FiHome'}>Home
                        </NavLink>
                    </Stack>

                    <Stack spacing="1">
                        <NavLink to='/app/my-learning' closeSideBar={closeSideBar}
                            aria-current={activePath === 'my-learning' ? 'page' : undefined} icon={'FaBookOpen'}>My
                            Learning</NavLink>
                    </Stack>

                    <Stack spacing="1">
                        <NavLink to='/app/events' closeSideBar={closeSideBar}
                            aria-current={activePath === 'events' ? 'page' : undefined}
                            icon={'MdEventAvailable'}>Events</NavLink>
                    </Stack>

                    <Stack spacing="1">
                        <NavLink to={'/app/profile'} closeSideBar={closeSideBar}
                            aria-current={activePath === `profile` ? 'page' : undefined}
                            icon={'CgProfile'}>Profile</NavLink>
                    </Stack>

                    <Stack spacing="1">
                        <NavLink to='/app/all-programs' closeSideBar={closeSideBar}
                            aria-current={activePath === 'all-programs' ? 'page' : undefined}
                            icon={'FaChalkboardTeacher'}>All Programs</NavLink>
                    </Stack>
                </Stack>
            </Stack>
        </Flex>
    )
}

const NavButton = (props: ButtonProps) => (
    <Button
        width="full"
        borderRadius="0"
        variant="ghost"
        size="lg"
        fontSize="sm"
        _hover={{ bg: mode('gray.100', 'gray.700') }}
        _active={{ bg: mode('gray.200', 'gray.600') }}
        _focus={{ boxShadow: 'none' }}
        _focusVisible={{ boxShadow: 'outline' }}
        {...props}
    />
)

interface NavLinkProps extends LinkProps {
    icon: any
    to?: string
    closeSideBar?: () => void
}


export const NavLink = (props: NavLinkProps) => {
    const navigate = useNavigate()
    const { icon, closeSideBar, ...linkProps }: any = props


    return (
        <Link
            onClick={closeSideBar}
            as={ReactRouterLink}
            to={props?.to}
            px="2"
            py="1.5"
            borderRadius="md"
            _hover={{ bg: mode('gray.100', 'gray.700') }}
            _activeLink={{
                bg: 'gray.700',
                color: 'white',
            }}
            {...linkProps}
        >

            <HStack justify="space-between">
                <HStack as="div" spacing="3">

                    <Icon as={_.get(iconConfig, icon)} />
                    <Text fontWeight={"semibold"} as="span" fontSize="sm" lineHeight="1.25rem">
                        {props.children}
                    </Text>

                </HStack>
                {props.isExternal && (
                    <Icon as={FiArrowUpRight} boxSize="4" color={mode('gray.600', 'gray.400')} />
                )}
            </HStack>
        </Link>
    )
}

const ParentNavLink = (props: any) => {

    const isActive = () => {
        try {
            if (props?.children.some((line: any) => props.activePath == line.path)) {
                return [0]
            }

            return []
        } catch (error) {
            return []
        }
    }

    return (
        <Accordion border={0} borderRadius={"md"} defaultIndex={isActive()} allowMultiple>
            <AccordionItem borderRadius={"md"} border={0}>
                <HStack
                    borderRadius="md"
                    // _hover={{ bg: mode('gray.100', 'gray.700') }}
                    _activeLink={{
                        bg: 'gray.700',
                        color: 'white',
                    }}>
                    <AccordionButton borderRadius={"md"} _hover={{ bg: "gray.100" }} py={1.5} px={2}>
                        <HStack justifyContent={"space-between"} w={"full"}>
                            <HStack as="a" spacing="3">
                                <Icon as={_.get(iconConfig, props?.icon)} />
                                <Text fontWeight={"semibold"} as="span" fontSize="sm" lineHeight="1.25rem">
                                    {props?.label}
                                </Text>
                            </HStack>
                            <AccordionIcon />
                        </HStack>
                    </AccordionButton>
                </HStack>
                <AccordionPanel pb={0}>
                    <Stack spacing="1">
                        {props.children.map((link: any, index: number) => (
                            <NavLink
                                key={index}
                                fontWeight={"semibold"}
                                pointerEvents={link.disabled ? 'none' : 'auto'}
                                className={link.disabled ? 'opacity-50' : ''}
                                aria-current={props.activePath === link.path ? 'page' : undefined}
                                onClick={link.onClick}
                                to={link?.to}
                                icon={link.icon}
                            >
                                {link.label}
                            </NavLink>
                        ))}
                    </Stack>

                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    )
}

export const NavHeading = (props: TextProps) => (
    <Text
        as="h4"
        fontSize="xs"
        fontWeight="semibold"
        px="2"
        lineHeight="1.25"
        color={mode('gray.600', 'gray.400')}
        {...props}
    />
)

const iconConfig = {
    "ImBooks": ImBooks,
    "BsBook": BsBook,
    "FiGrid": FiGrid,
    "HiMiniClipboardDocumentCheck": HiMiniClipboardDocumentCheck,
    "MdSubject": MdSubject,
    "FaBitbucket": FaBitbucket,
    "FiArrowRight": FiArrowRight,
    "BsBuilding": BsBuilding,
    "ImManWoman": ImManWoman,
    "BsFillClipboard2CheckFill": BsFillClipboard2CheckFill,
    "FaChalkboardTeacher": FaChalkboardTeacher,
    "BsBriefcaseFill": BsBriefcaseFill,
    "BsFillGrid1X2Fill": BsFillGrid1X2Fill,
    "BsTagFill": BsTagFill,
    "BsFolder": BsFolder,
    "FiHome": FiHome,
    "FaRegCalendarAlt": FaRegCalendarAlt,
    "FaBookOpen": FaBookOpen,
    "MdEventAvailable": MdEventAvailable,
    "CgProfile": CgProfile,
}