import _ from "lodash"

const GeneralInfoTabPanel = ({ data }: any) => {

    return (
        <div className=" rounded-[15px]">
            <div className="flex bg-white border-b w-full p-5 rounded-t-[15px]">
                <div className=" basis-1/5 flex justify-between pr-10">
                    <p className=" font-bold">Module Code</p>
                    <p>
                        :
                    </p>
                </div>
                <div className="">
                    <p className=" ">
                        {_.get(data, 'code') ?? "___"}
                    </p>
                </div>
            </div>
            <div className="flex w-full bg-white border-b p-5 ">
                <div className=" basis-1/5 flex justify-between pr-10">
                    <p className="font-bold">Module Name

                    </p>
                    <p>
                        :
                    </p>
                </div>
                <div>
                    <p className="">
                        {_.get(data, 'title') ?? "___"}
                    </p>
                </div>
            </div>
            <div className="flex w-full bg-white border-b p-5 ">
                <div className=" basis-1/5 flex justify-between pr-10">
                    <p className="font-bold">Assesment Criteria

                    </p>
                    <p>
                        :
                    </p>
                </div>
                <div>
                    <p className="">
                        {_.get(data, 'assessment_criteria') ?? "___"}
                    </p>
                </div>
            </div>
            <div className="flex w-full bg-white border-b p-5 ">
                <div className=" basis-1/5 flex justify-between pr-10">
                    <p className="font-bold">Type</p>
                    <p>
                        :
                    </p>
                </div>
                <div>
                    {
                        _.get(data, 'type') === 'Elective' ?
                            <span
                                className="bg-[#6ee29a] bg-opacity-30 text-xs px-3 py-1 rounded-md text-[#053102] text-opacity-70">
                                {_.get(data, 'type') ?? "___"}
                            </span>
                            :
                            <span className="bg-[#DC4B4B] bg-opacity-20 text-xs px-3 py-1 rounded-md text-[#700]">
                                {_.get(data, 'type') ?? "___"}
                            </span>
                    }

                </div>
            </div>
            <div className="flex w-full bg-white border-b p-5 ">
                <div className=" basis-1/5 flex justify-between pr-10">
                    <p className="font-bold">Duration</p>
                    <p> :
                    </p>
                </div>
                <div>
                    <p className="">
                        {_.get(data, 'duration') ?? "___"}
                    </p>
                </div>
            </div>
            <div className="flex w-full bg-white border-b p-5 ">
                <div className=" basis-1/5 flex justify-between pr-10">
                    <p className="font-bold">Duration (Hours)</p>
                    <p> :
                    </p>
                </div>
                <div>
                    <p className="">
                        {_.get(data, 'duration_hours') ?? "___"}
                    </p>
                </div>
            </div>
            <div className="flex w-full bg-white border-b  p-5 ">
                <div className=" basis-1/5 flex justify-between pr-10">
                    <p className="font-bold">Credit</p>
                    <p> :
                    </p>
                </div>
                <div>
                    <p className="">
                        {_.get(data, 'credit') ?? "___"}
                    </p>
                </div>
            </div>
            <div className="flex w-full bg-white border-b  p-5 ">
                <div className=" basis-1/5 flex justify-between pr-10">
                    <p className="font-bold">Group</p>
                    <p> :
                    </p>
                </div>
                <div>
                    <p className="">
                        {_.get(data, 'group') ?? "___"}
                    </p>
                </div>
            </div>
            <div className="flex w-full bg-white border-b rounded-b-[15px] p-5 ">
                <div className=" basis-1/5 flex justify-between pr-10">
                    <p className="font-bold">Registration Closing Date</p>
                    <p> :
                    </p>
                </div>
                <div>
                    <p className="">
                        {_.get(data, 'enrollment_closing_date') ?? "___"}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default GeneralInfoTabPanel


